import React, { Component } from 'react';
import {
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { Container } from 'reactstrap';
import Header from './common/Header/Header';
import Sidebar from './common/Sidebar/Sidebar';
import Footer from './common/Footer';
import Dashboard from './Dashboard/Dashboard';
import SupplierInfo from './SupplierInfo/SupplierInfo';
import OrderStrategy from './OrderStrategy/OrderStrategy';
import OrderStatuses from './OrderStatuses/OrderStatuses';
import OrderHistory from './OrderHistory/OrderHistory';
import PlaceOrder from './PlaceOrder/PlaceOrder';
import CompanyInfo from './CompanyInfo/CompanyInfo';
import UserInfo from './UserInfo/UserInfo';
import BtoB from './BtoB/BtoB';
import Import from './Import/Import';
import MyStyles from './MyStyles/MyStyles';
import Notifications from './Notifications/Notifications';
import Export from './Export/Export';
import InstallDocs from './InstallDocs/InstallDocs';
import OrderParameters from './OrderParameters/OrderParameters';
import NotFoundPage from './common/NotFoundPage';
import ServerErrorPage from './common/ServerErrorPage';
import FAB from './Utility/FAB';


const styles = {
  mainStyle : { paddingTop:'15px' },
};




class Home extends Component {
  render() {
    return (
      <div className='app'>
        <Header  />
        <div className='app-body'>
          <Sidebar {...this.props} />
          <main className='main' style = { styles.mainStyle }>
            {/*<Breadcrumb />*/}
            <Container fluid>
              <Switch>
                <Route path='/dashboard' name='Dashboard' component={Dashboard} />
                <Route path='/placeOrder' name='PlaceOrder' component={PlaceOrder} />
                <Route path='/supplierInfo' name='SupplierInfo' component={SupplierInfo} />
                <Route path='/orderStrategy' name='OrderStrategy' component={OrderStrategy} />
                <Route path='/orderStatuses' name='OrderStatuses' component={OrderStatuses} />
                <Route path='/orderHistory' name='OrderHistory' component={OrderHistory} />
                <Route path='/companyInfo' name='CompanyInfo' component={CompanyInfo} />
                <Route path='/userInfo' name='UserInfo' component={UserInfo} />
                <Route path='/btoB' name='BtoB' component={BtoB} />
                <Route path='/import' name='Import' component={Import} />
                <Route path='/myStyles' name='MyStyles' component={MyStyles} />
                <Route path='/notifications' name='Notifications' component={Notifications} />
                <Route path='/export' name='Export' component={Export} />
                <Route path='/installDocs' name='InstallDocs' component={InstallDocs} />
                <Route path='/orderParameters' name='OrderParameters' component={OrderParameters} />
                <Redirect from='/' to='/dashboard' />
                <Route exact path='/NotFound' name='Not Found Page' component={NotFoundPage} />
                <Route exact path='/ServerError' name='Server Error Page' component={ServerErrorPage} />
              </Switch>
            </Container>
            <FAB />
          </main>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
