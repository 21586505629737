import React, { Component } from 'react';
import { Row, Col  } from 'reactstrap';
import * as Cards from './../Utility/Cards';

class Dashboard extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        
        <Row>
          <Col><Cards.DashboardCardOrderHistory /></Col>
          <Col><Cards.DashboardCardBackOrders /></Col>
        </Row>

        <Row>
          <Col><Cards.DashboardCardSavings txtHeader = {'Savings Last 30 Days'} txtBody = { '$1,234' } txtPill = {"$893"} /></Col>
          <Col><Cards.DashboardCardSavings txtHeader = {'Savings Last 30 Days'} txtBody = { '$1,234' } txtPill = {"$893"} /></Col>
          <Col><Cards.DashboardCardSavings txtHeader = {'Savings Last 30 Days'} txtBody = { '$1,234' } txtPill = {"$893"} /></Col>
          <Col><Cards.DashboardCardSavings txtHeader = {'Savings Last 30 Days'} txtBody = { '$1,234' } txtPill = {"$893"} /></Col>
        </Row>

       
      </div>
    );
  }
}



Dashboard.propTypes = {

};

export default Dashboard;
