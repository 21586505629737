import React, { Component }                               from 'react';
import PropTypes                                          from 'prop-types';
import { Container, Row, Col, Card, CardGroup, CardBody, } from 'reactstrap';
import LoginForm                                          from './LoginForm';
import Snackbar from '@material-ui/core/Snackbar';
import wbysLogo from "./wbysLogo.png";






// --------------------------------------------------------
// --- Styling Settings To Be Used Inside The Component ---
// --------------------------------------------------------
const styles = {
  formStyle : { backgroundColor : 'black' },
  alignLogo: {textAlign : 'center', marginTop: -75 },
  imgStyle: {padding: 0, backgroundColor : 'black'},
  cardStyle: { backgroundColor : 'black', borderColor: '#20a8d8' },
  headerStyle :{textAlign : 'center',},
};



class LoginPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.onLoginClick    = this.onLoginClick.bind(this);
    this.updateUserState = this.updateUserState.bind(this);
    this.state = {
      user: { username: '', password: '', },
      showToast: false,
      toastMessage: '',
      toastSupport: '',
    };
  }


  // --- Notification Toast Action Listener
  handleToastClose = (event, reason) => {
    if (reason === 'clickaway') { return; }
    this.setState({ showToast: false });
  };

  // ------------------------------------------------------
  // --- Will Get Triggered After Clicking Login Button ---
  // ------------------------------------------------------




  onLoginClick(e) {
    e.preventDefault();
    this.props.onLoginUser(this.state.user)
      .then(() => {
        if (this.props.errorMessage) {
          let errorMessage = this.props.errorMessage;
               if (errorMessage === 'Session Logon Status Illegal Logon'   ) errorMessage = 
                  'Username is Missing or Invalid - Please try again.';
          else if (errorMessage === 'Session Logon Status Illegal Password') errorMessage = 
                  'Password is Missing or Invalid - Please try again.';
          console.log(errorMessage);
          this.setState ({
            showToast : true,
            toastMessage : `${errorMessage}`,
            toastSupport : 'If you are unable to login,  contact FloorSoft for Support @ 706-226-0960',
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }



  // -----------------------------------------------------------------------------------
  // --- Will Get Triggered Everytime Something Is Typed On Password/UserName Fields ---
  // -----------------------------------------------------------------------------------
  updateUserState(e) {
    const { name, value } = e.target;
    this.setState({ user: { ...this.state.user, [name]: value } });
  }

  render() {
    return (

      <div className='app flex-row align-items-center' style = { styles.formStyle }>
      <Snackbar
        anchorOrigin     = { { vertical: 'top', horizontal: 'center', } }
        open             = { this.state.showToast }
        autoHideDuration = { 8000 }
        onClose          = { this.handleToastClose }
        message          = { <span id="message-id"><br/>{ this.state.toastMessage }<br/><br/>{this.state.toastSupport}</span> }
        className        = "LoginSnackbar"
      >
      </Snackbar>
        <Container>
         
          <Row className='justify-content-center'>
            <Col md='6'>
              <CardGroup className='mb-4'>
                <Card style = { styles.cardStyle } className='p-4'>
                  <div style={styles.alignLogo}>
                    <img style={styles.imgStyle} src={wbysLogo} width="300" height="auto" alt="We Buy Your Shorts" />
                  </div>
                  <CardBody >
                    <div style={styles.headerStyle}>
                      <h1 className='text-muted'>Sign In</h1>
                      <p className='text-muted'>Using a WBYS or FloorWizard account</p>
                    </div>
                 
                    <LoginForm
                      user         = { this.state.user      }
                      handleChange = { this.updateUserState }
                      handleSubmit = { this.onLoginClick    }
                    />
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

LoginPage.propTypes = {
  onLoginUser  : PropTypes.func.isRequired,
  errorMessage : PropTypes.object,
};

export default LoginPage;
