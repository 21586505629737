import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Container} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as orderActions from '../../actions/orderActions';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LogoffButton from '../common/Header/LogoffButton.js'
import {Table as BootStrapTable}  from 'reactstrap';
import Input from '@material-ui/core/Input';
import PRODUCTS from '../../api/PRODUCTS';
import MaterialTable from 'material-table';
import * as WaitScreen from '../common/WaitScreen.js';
import moment from 'moment-timezone';
import axios from 'axios';




const styles = {
  textField: {
    width: '100%',
  },
  selectInput: {
    width: '100%',
    marginTop: 2,
  },
  container: {
    backgroundColor: '#000',
    padding:"2.5rem",
    
  },
  paper : {
    backgroundColor: 'transparent',

  },
  buttonContainer : {
    backgroundColor: 'none',
    marginTop: 35,
  },
  title : {
    marginTop: '2.5rem',
  },
  stepButtonContainer :{
    display:'flex', 
    justifyContent:'space-between',
    marginBottom: 30
  },
  stepButtonIcon : {
    marginRight: 10,
  },
  addButtonPadding :{
    paddingRight:20,
    paddingLeft:20,
  },
  tableHeader : {
    fontSize: '1.1rem',
  },
  orderSummaryTable :{
    backgroundColor: '#111',
    color: '#fff'
  },
  tablePaddingLeft50 : {
    paddingLeft : 50,
    paddingTop : 5,
    paddingBottom : 5,
  },
  tablePaddingLeft25 : {
    paddingLeft : 25,
    paddingTop : 5,
    paddingBottom : 5,
  },
  tableCellBold : {
    fontWeight: 600,
  },

}


const INITIAL_STATE = {
  cutFeet                                    : ''                               ,
  cutInches                                  : ''                               ,
  cutLabel                                   : ''                               ,
  cutLength                                  : ''                               ,
  cutList                                    : []                               ,
  totalCuts                                  : ''                               ,
  orderLength                                : 0                                ,
  minCutLength                               : ''                               ,
  orderLengthFeet                            : 0                                ,
  orderLengthInches                          : 0                                ,
  buyerName                                  : ''                               ,
  buyerCompanyName                           : ''                               ,
  buyerEmail                                 : ''                               ,
  buyerMobilePhone                           : ''                               ,
  buyerAddress                               : ''                               ,
  buyerAddress2                              : ''                               ,
  buyerCity                                  : ''                               ,
  buyerState                                 : ''                               ,
  buyerZip                                   : ''                               ,
  buyerCountry                               : ''                               ,
  optionalBuyerFieldsAreVisible              : false                            ,
  projectMeasuredWidth                       : ''                               ,
  projectMatchType                           : ''                               ,
  projectPatternWidth                        : ''                               ,
  projectPatternLength                       : ''                               ,
  projectStepValue                           : ''                               ,
  projectDropValue                           : ''                               ,
  projectCutLabel                            : ''                               ,
  projectCutLengthFeet                       : ''                               ,
  projectCutLengthInches                     : ''                               ,
  projectDocument1                           : ''                               ,
  projectComments                            : ''                               ,
  activeStepIsBuyerInfo                      : true                             ,           
  activeStepIsEstimationDetails              : false                            ,
  activeStepIsProductInfo                    : false                            ,
  activeStepIsShippingInfo                   : false                            ,
  activeStepIsOrderSummary                   : false                            ,
  activeStepIsConfirmation                   : false                            , 
  shippingName                               : ''                               ,
  shippingCompanyName                        : ''                               ,
  shippingEmail                              : ''                               ,
  shippingPhone                              : ''                               ,
  shippingAddress                            : ''                               ,
  shippingAddress2                           : ''                               ,
  shippingCity                               : ''                               ,
  shippingState                              : ''                               ,
  shippingZip                                : ''                               ,
  shippingCountry                            : ''                               ,
  shippingSidemark                           : ''                               ,
  shippingNote                               : ''                               ,
  shippingAddressIsInstallationSite          : false                            ,
  shippingAddressIsDefault                   : false                            ,
  shippingAddressIsOther                     : false                            ,
  shippingAddressType                        : ''                               ,
  shippingInputsAreVisible                   : false                            ,
  productSKU                                 : ''                               ,
  productWidth                               : ''                               ,
  productStyle                               : ''                               ,
  productColor                               : ''                               ,
  productBacking                             : ''                               ,
  productSubtype                             : ''                               ,
  productMatchType                           : ''                               ,
  productPRWidth                             : ''                               ,
  productPRLength                            : ''                               ,
  productStepValue                           : ''                               ,
  productDropValue                           : ''                               ,
  productFaceWeight                          : ''                               ,
  productTwist                               : ''                               ,
  retailerDefaultName                        : 'Freddie Freeman'                ,
  retailerDefaultCompanyName                 : 'Atlanta Braves'                 ,
  retailerDefaultEmail                       : 'freddie@chopshop.com'           ,
  retailerDefaultPhone                       : '706-313-5564'                   ,
  retailerDefaultShippingAddress             : '500 Somewhere Over the Rainbow' ,
  retailerDefaultShippingAddress2            : 'Suite 205'                      ,
  retailerDefaultShippingCity                : 'Atlanta'                        ,
  retailerDefaultShippingState               : 'GA'                             ,
  retailerDefaultShippingZip                 : '30729'                          ,
  retailerDefaultShippingCountry             : 'USA'                            ,
  skuStyle                                   : ''                               ,
  skuColor                                   : ''                               ,
  sku                                        : ''                               ,
  skuWidth                                   : ''                               ,
  skuBacking                                 : ''                               ,
  productIsFaceIn                            : true                             ,
  productIsWrapped                           : true                             ,
  productSelvidgeOn                          : false                            ,
};

//--------------------------------------------------------
// ---------  STEP BUTTON ICONS  -------------- 
// -------------------------------------------------------

function OneIcon(props) {
  return (
    <SvgIcon {...props}  style={styles.stepButtonIcon}>
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14h-2V9h-2V7h4v10z"/>
    </SvgIcon>
  );
}

function TwoIcon(props) {
  return (
    <SvgIcon {...props}  style={styles.stepButtonIcon}>
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 8c0 1.11-.9 2-2 2h-2v2h4v2H9v-4c0-1.11.9-2 2-2h2V9H9V7h4c1.1 0 2 .89 2 2v2z"/>
    </SvgIcon>
  );
}

function ThreeIcon(props) {
  return (
    <SvgIcon {...props}  style={styles.stepButtonIcon}>
      <path d="M19 3H5.01c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-3.99 7.5c0 .83-.67 1.5-1.5 1.5.83 0 1.5.67 1.5 1.5V15c0 1.11-.9 2-2 2H10c-.55 0-1-.45-1-1s.45-1 1-1h3.01L13 13h-1c-.55 0-1-.45-1-1s.45-1 1-1h1l.01-2H10c-.55 0-.99-.45-.99-1s.44-1 .99-1h3.01c1.1 0 2 .9 2 2v1.5z"/>
    </SvgIcon>
  );
}

function FourIcon(props) {
  return (
    <SvgIcon {...props}  style={styles.stepButtonIcon}>
      <path d="M19.04 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-5 14c-.55 0-1-.45-1-1v-3h-3c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v3h2V8c0-.55.45-1 1-1s1 .45 1 1v8c0 .55-.45 1-1 1z"/>
    </SvgIcon>
  );
}

function FiveIcon(props) {
  return (
    <SvgIcon {...props}  style={styles.stepButtonIcon}>
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 6h-4v2h2c1.1 0 2 .89 2 2v2c0 1.11-.9 2-2 2H9v-2h4v-2H9V7h6v2z"/>
    </SvgIcon>
  );
}



class PlaceOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
        buyerName                          : ''                               ,
        buyerCompanyName                   : ''                               ,
        buyerEmail                         : ''                               ,
        buyerMobilePhone                   : ''                               ,
        buyerPhone2                        : ''                               ,
        buyerAddress                       : ''                               ,
        buyerAddress2                      : ''                               ,
        buyerCity                          : ''                               ,
        buyerState                         : ''                               ,
        buyerZip                           : ''                               ,
        buyerCountry                       : ''                               ,
        optionalBuyerFieldsAreVisible      : false                            ,
        projectMeasuredWidth               : ''                               ,
        projectMatchType                   : ''                               ,
        projectPatternWidth                : ''                               ,
        projectPatternLength               : ''                               ,
        projectStepValue                   : ''                               ,
        projectDropValue                   : ''                               ,
        patternWidthIsVisible              : false                            ,
        patternLengthIsVisible             : false                            ,
        patternStepValueIsVisible          : false                            ,
        patternDropValueIsVisible          : false                            ,
        projectCutLabel                    : ''                               ,
        projectCutLengthFeet               : ''                               ,
        projectCutLengthInches             : ''                               ,
        projectDocument1                   : ''                               ,
        projectComments                    : ''                               ,
        cutFeet                            : ''                               ,
        cutInches                          : ''                               ,
        cutLabel                           : ''                               ,
        cutLength                          : ''                               ,
        totalCuts                          : ''                               ,
        orderLength                        : 0                                ,
        orderLengthFeet                    : 0                                ,
        orderLengthInches                  : 0                                ,
        minCutLength                       : ''                               ,
        cutList                            : []                               ,
        activeStepIsBuyerInfo              : true                             ,           
        activeStepIsEstimationDetails      : false                            ,
        activeStepIsProductInfo            : false                            ,
        activeStepIsShippingInfo           : false                            ,
        activeStepIsOrderSummary           : false                            ,
        activeStepIsConfirmation           : false                            ,  
        shippingName                       : ''                               ,
        shippingCompanyName                : ''                               ,
        shippingEmail                      : ''                               ,
        shippingPhone                      : ''                               ,
        shippingAddress                    : ''                               ,
        shippingAddress2                   : ''                               ,
        shippingCity                       : ''                               ,
        shippingState                      : ''                               ,
        shippingZip                        : ''                               ,
        shippingCountry                    : ''                               ,
        shippingSidemark                   : ''                               ,
        shippingNote                       : ''                               ,
        shippingAddressIsInstallationSite  : false                            ,
        shippingAddressIsDefault           : false                            ,
        shippingAddressIsOther             : false                            ,
        shippingAddressType                : ''                               ,
        shippingInputsAreVisible           : false                            ,
        retailerDefaultName                : 'Freddie Freeman'                ,
        retailerDefaultCompanyName         : 'Atlanta Braves'                 ,
        retailerDefaultEmail               : 'freddie@chopshop.com'           ,
        retailerDefaultPhone               : '706-313-5564'                   ,
        retailerDefaultShippingAddress     : '500 Somewhere Over the Rainbow' ,
        retailerDefaultShippingAddress2    : 'Suite 205'                      ,
        retailerDefaultShippingCity        : 'Atlanta'                        ,
        retailerDefaultShippingState       : 'GA'                             ,
        retailerDefaultShippingZip         : '30729'                          ,
        retailerDefaultShippingCountry     : 'USA'                            ,
        productSKU                         : ''                               ,
        productWidth                       : ''                               ,
        productStyle                       : ''                               ,
        productColor                       : ''                               ,
        productBacking                     : ''                               ,
        productSubtype                     : ''                               ,
        productMatchType                   : ''                               ,
        productPRWidth                     : ''                               ,
        productPRLength                    : ''                               ,
        productStepValue                   : ''                               ,
        productDropValue                   : ''                               ,
        productFaceWeight                  : ''                               ,
        productTwist                       : ''                               ,
        productNotFoundMessage             : ''                               ,
        skuStyle                           : '1111111'                        ,
        skuColor                           : '2222222'                        ,
        sku                                : '1111111222222233333334444444'   ,
        skuWidth                           : '3333333'                        ,
        skuBacking                         : '4444444'                        ,
        companyCode                        : 'C'                              ,
        companyId                          : '123456'                         ,
        stockCheckResponse                 : 'No Response Yet'                ,
        productIsFaceIn                    : true                             ,
        productIsWrapped                   : true                             ,
        productSelvidgeOn                  : false                            ,
    }; 

    this.handleChange                            =  this.handleChange.bind(this)                            ;                                           
    this.handleActiveStepIsBuyerInfo             =  this.handleActiveStepIsBuyerInfo.bind(this)             ;
    this.handleActiveStepIsEstimationDetails     =  this.handleActiveStepIsEstimationDetails.bind(this)     ;
    this.handleActiveStepIsProductInfo           =  this.handleActiveStepIsProductInfo.bind(this)           ;
    this.handleActiveStepIsShippingInfo          =  this.handleActiveStepIsShippingInfo.bind(this)          ;
    this.handleActiveStepIsOrderSummary          =  this.handleActiveStepIsOrderSummary.bind(this)          ;
    this.handleResetForm                         =  this.handleResetForm.bind(this)                         ;
    this.handleshippingAddressIsInstallationSite =  this.handleshippingAddressIsInstallationSite.bind(this) ;
    this.handleShippingAddressIsDefault          =  this.handleShippingAddressIsDefault.bind(this)          ;
    this.handleShippingAddressIsOther            =  this.handleShippingAddressIsOther.bind(this)            ;

  }  
//--------------------------------------------------------
// ---------  INPUT FIELD CHANGE LISTENER  -------------- 
// -------------------------------------------------------

  handleChange(changeType, event) {
    switch(changeType) {
      case 'buyerName'                     : this.setState({buyerName                    : event.target.value}); break;
      case 'buyerCompanyName'              : this.setState({buyerCompanyName             : event.target.value}); break;
      case 'buyerEmail'                    : this.setState({buyerEmail                   : event.target.value}); break;
      case 'buyerMobilePhone'              : this.setState({buyerMobilePhone             : event.target.value}); break;
      case 'buyerAddress'                  : this.setState({buyerAddress                 : event.target.value}); break;
      case 'buyerAddress2'                 : this.setState({buyerAddress2                : event.target.value}); break;
      case 'buyerCity'                     : this.setState({buyerCity                    : event.target.value}); break;
      case 'buyerState'                    : this.setState({buyerState                   : event.target.value}); break;
      case 'buyerZip'                      : this.setState({buyerZip                     : event.target.value}); break;
      case 'buyerCountry'                  : this.setState({buyerCountry                 : event.target.value}); break;
      case 'projectMeasuredWidth'          : this.setState({projectMeasuredWidth         : event.target.value}); break;
      case 'projectPatternWidth'           : this.setState({projectPatternWidth          : event.target.value}); break;
      case 'projectPatternLength'          : this.setState({projectPatternLength         : event.target.value}); break;
      case 'projectStepValue'              : this.setState({projectStepValue             : event.target.value}); break;
      case 'projectDropValue'              : this.setState({projectDropValue             : event.target.value}); break;
      case 'projectCutLabel'               : this.setState({projectCutLabel              : event.target.value}); break;
      case 'projectCutLengthFeet'          : this.setState({projectCutLengthFeet         : event.target.value}); break;
      case 'projectCutLengthInches'        : this.setState({projectCutLengthInches       : event.target.value}); break;
      case 'projectDocument1'              : this.setState({projectDocument1             : event.target.value}); break;
      case 'projectComments'               : this.setState({projectComments              : event.target.value}); break;
      case 'shippingName'                  : this.setState({shippingName                 : event.target.value}); break;
      case 'shippingCompanyName'           : this.setState({shippingCompanyName          : event.target.value}); break;
      case 'shippingEmail'                 : this.setState({shippingEmail                : event.target.value}); break;
      case 'shippingPhone'                 : this.setState({shippingPhone                : event.target.value}); break;
      case 'shippingAddress'               : this.setState({shippingAddress              : event.target.value}); break;
      case 'shippingAddress2'              : this.setState({shippingAddress2             : event.target.value}); break;
      case 'shippingCity'                  : this.setState({shippingCity                 : event.target.value}); break;
      case 'shippingState'                 : this.setState({shippingState                : event.target.value}); break;
      case 'shippingZip'                   : this.setState({shippingZip                  : event.target.value}); break;
      case 'shippingCountry'               : this.setState({shippingCountry              : event.target.value}); break;
      case 'shippingSidemark'              : this.setState({shippingSidemark             : event.target.value}); break;
      case 'shippingNote'                  : this.setState({shippingNote                 : event.target.value}); break;
      case 'shippingAddressType'           : this.setState({shippingAddressType          : event.target.value}); break;
      case 'productSKU'                    : this.setState({productSKU                   : event.target.value}); break;
      default:;
    }
  }

//--------------------------------------------------------
// ---------  Active Step Listeners  -------------- 
// -------------------------------------------------------

  handleActiveStepIsBuyerInfo() {
      this.setState({
        activeStepIsBuyerInfo     : true   , 
        activeStepIsEstimationDetails   : false  , 
        activeStepIsProductInfo   : false  , 
        activeStepIsShippingInfo  : false  ,  
        activeStepIsOrderSummary  : false  ,  
      })
    }

    handleActiveStepIsEstimationDetails() {
      this.setState({
        activeStepIsBuyerInfo     : false  , 
        activeStepIsEstimationDetails   : true   , 
        activeStepIsProductInfo   : false  , 
        activeStepIsShippingInfo  : false  ,  
        activeStepIsOrderSummary  : false  ,  
      })
    }

    handleActiveStepIsProductInfo() {
      this.setState({
        activeStepIsBuyerInfo     : false  , 
        activeStepIsEstimationDetails   : false  , 
        activeStepIsProductInfo   : true   , 
        activeStepIsShippingInfo  : false  ,  
        activeStepIsOrderSummary  : false  ,  
      })
    }

    handleActiveStepIsShippingInfo() {
      this.setState({
        activeStepIsBuyerInfo     : false  , 
        activeStepIsEstimationDetails   : false  , 
        activeStepIsProductInfo   : false  , 
        activeStepIsShippingInfo  : true   ,  
        activeStepIsOrderSummary  : false  ,  
      })
    }

    handleActiveStepIsOrderSummary() {
      this.setState({
        activeStepIsBuyerInfo     : false  , 
        activeStepIsEstimationDetails   : false  , 
        activeStepIsProductInfo   : false  , 
        activeStepIsShippingInfo  : false  ,  
        activeStepIsOrderSummary  : true   ,  
      })
    }

//------------------------------------------------------ 
// ---------  Handle Shipping Address selection   -------------- 
// -----------------------------------------------------

handleshippingAddressIsInstallationSite() {
    this.setState({
      shippingName                        : this.state.buyerName        ,
      shippingCompanyName                 : this.state.buyerCompanyName ,
      shippingEmail                       : this.state.buyerEmail       ,
      shippingPhone                       : this.state.buyerMobilePhone ,
      shippingAddress                     : this.state.buyerAddress     ,
      shippingAddress2                    : this.state.buyerAddress2    ,
      shippingCity                        : this.state.buyerCity        ,
      shippingState                       : this.state.buyerState       ,
      shippingZip                         : this.state.buyerZip         ,
      shippingCountry                     : this.state.buyerCountry     ,
      shippingAddressIsInstallationSite   : true                        ,
      shippingAddressIsDefault            : false                       ,
      shippingAddressIsOther              : false                       ,
      shippingAddressType                 : 'Installation Site'         ,
      shippingInputsAreVisible            : true                        ,
    });
}

handleShippingAddressIsDefault() {
    this.setState({
      shippingName                        : this.state.retailerDefaultName             ,
      shippingCompanyName                 : this.state.retailerDefaultCompanyName      ,
      shippingEmail                       : this.state.retailerDefaultEmail            ,
      shippingPhone                       : this.state.retailerDefaultPhone            ,
      shippingAddress                     : this.state.retailerDefaultShippingAddress  ,
      shippingAddress2                    : this.state.retailerDefaultShippingAddress2 ,
      shippingCity                        : this.state.retailerDefaultShippingCity     ,
      shippingState                       : this.state.retailerDefaultShippingState    ,
      shippingZip                         : this.state.retailerDefaultShippingZip      ,
      shippingCountry                     : this.state.retailerDefaultShippingCountry  ,
      shippingAddressIsInstallationSite   : false                                      ,
      shippingAddressIsDefault            : true                                       ,
      shippingAddressIsOther              : false                                      ,
      shippingAddressType                 : 'Default'                                  ,
      shippingInputsAreVisible            : true                                       ,
    });
}

handleShippingAddressIsOther() {
    this.setState({
      shippingName                        :''        ,
      shippingCompanyName                 :''        ,
      shippingEmail                       :''        ,
      shippingPhone                       :''        ,
      shippingAddress                     :''        ,
      shippingAddress2                    :''        ,
      shippingCity                        :''        ,
      shippingState                       :''        ,
      shippingZip                         :''        ,
      shippingCountry                     :''        ,
      shippingAddressIsInstallationSite   : false    ,
      shippingAddressIsDefault            : false    ,
      shippingAddressIsOther              : true     ,
      shippingAddressType                 : 'Other'  ,
      shippingInputsAreVisible            : true     ,
    });
}

//---------------------------------------------------------------------------
//-------  Handle product flags  --------
//---------------------------------------------------------------------------

handleProductIsFaceIn = () => {
  this.setState({
    productIsFaceIn                        :false        ,
  });
}

handleProductIsWrapped = () => {
  this.setState({
    productIsWrapped                       :false        ,
  });
}

handleProductSelvidgeOn = () => {
  this.setState({
    productSelvidgeOn                      :true        ,
  });
}

//---------------------------------------------------------------------------
//-------  Set projectMatchType value and show/hide PR Input fields  --------
//---------------------------------------------------------------------------

handleMatchTypeChange(event) {
  switch(event.target.value) {
    case ''          : 
      this.setState({
        projectMatchType                : ''            ,
        patternWidthIsVisible           : false         ,
        patternLengthIsVisible          : false         ,
        patternStepValueIsVisible       : false         ,
        patternDropValueIsVisible       : false         ,
    }); break;
    case 'None'          : 
      this.setState({
        projectMatchType                : 'None'        ,
        patternWidthIsVisible           : false         ,
        patternLengthIsVisible          : false         ,
        patternStepValueIsVisible       : false         ,
        patternDropValueIsVisible       : false         ,
    }); break;
    case 'Set Match'  : 
      this.setState({
        projectMatchType                : 'Set Match'   ,
        patternWidthIsVisible           : true          ,
        patternLengthIsVisible          : true          ,
        patternStepValueIsVisible       : false         ,
        patternDropValueIsVisible       : false         ,
    }); break;
    case 'Drop Match' : 
      this.setState({
        projectMatchType                : 'Drop Match'  ,
        patternWidthIsVisible           : true          ,
        patternLengthIsVisible          : true          ,
        patternStepValueIsVisible       : false         ,
        patternDropValueIsVisible       : true          ,
    }); break;
    case 'Step Match' : 
      this.setState({
        projectMatchType                : 'Step Match'  ,
        patternWidthIsVisible           : true          ,
        patternLengthIsVisible          : true          ,
        patternStepValueIsVisible       : true          ,
        patternDropValueIsVisible       : false         ,
    }); break;
    default:;
  }
} 

 
//----------------------------------------------------
// ---------  Handle Cut List Section input changes --
// ---------------------------------------------------

onChangeValue = event => {
  this.setState({ [event.target.name]: event.target.value});
};

//----------------------------------------------------
// ---------  Add cut upon selection of Inches value --
// ---------------------------------------------------

onChangeValueInches = event => {
  this.setState({ [event.target.name]: event.target.value});
  this.setState(state => {
    const cutList = [...state.cutList, {'cutLabel': state.cutLabel, 'cutFeet': state.cutFeet, 'cutInches': state.cutInches, 'cutLength': parseInt(state.cutInches)+state.cutFeet*12 }];
      return {
        cutList         ,
        cutLabel    : '',
        cutFeet     : '',
        cutInches   : '',
        cutLength   : '',
      };
    });
};

//--------------------------------------------------------------- 
// ---------  Remove cut item from cutList array   -------------- 
// --------------------------------------------------------------

onRemoveItem = cutLabel => {
  this.setState(state => {
      const cutList = state.cutList.filter(item => item.cutLabel !== cutLabel);
      return {
          cutList
      };
  });
};

//---------------------------------------------------------- 
// ---------  ADD cut item to cutList array   -------------- 
// --------------------------------------------------------- 

onAddItem = () => {
  this.setState(state => {
  const cutList = [...state.cutList, {'cutLabel': state.cutLabel, 'cutFeet': state.cutFeet, 'cutInches': state.cutInches, 'cutLength': parseInt(state.cutInches)+state.cutFeet*12 }];
    return {
      cutList         ,
      cutLabel    : '',
      cutFeet     : '',
      cutInches   : '',
      cutLength   : '',
    };
  });

};

//----------------------------------------
// ---------  UPDATE CUT VALUES       ----
// ---------------------------------------

/* This is not a direct mutation. the original array was cloned, modified and then the state was set again using the cloned Array */
onUpdateItem = (event, i, key) => {
  const newItems = [...this.state.cutList ];
  newItems[i][key] = event.target.value;
  this.setState({ cutList: newItems });
};

//--------------------------------------------------------------- 
// ---------  Find Sum of all cuts in Array   -------------- 
// --------------------------------------------------------------

sumCutLengthProperty = (arr, type) => {
  return arr.reduce((total, obj) => {
    if (typeof obj[type] === 'string') {
      return total + Number(obj[type]);
    }
    return total + obj[type];
  }, 0);
}

//----------------------------------------
// ---------  RESET FORM VALUES   --------
// ---------------------------------------

handleResetForm = () => {
  this.setState({ ...INITIAL_STATE });
};



  // ----------------------------
  // --- Finish Buttton Listener ---
  // ----------------------------
  handleFormComplete = () => {
    let orderData = {
        'buyerName'                         : this.state.buyerName                   ,  
        'buyerCompanyName'                  : this.state.buyerCompanyName            ,   
        'buyerEmail'                        : this.state.buyerEmail                  ,  
        'buyerMobilePhone'                  : this.state.buyerMobilePhone            ,   
        'buyerPhone2'                       : this.state.buyerPhone2                 ,   
        'buyerAddress'                      : this.state.buyerAddress                ,   
        'buyerAddress2'                     : this.state.buyerAddress2               ,   
        'buyerCity'                         : this.state.buyerCity                   ,  
        'buyerState'                        : this.state.buyerState                  ,  
        'buyerZip'                          : this.state.buyerZip                    ,         
        'buyerCountry'                      : this.state.buyerCountry                ,  
        'projectMeasuredWidth'              : this.state.projectMeasuredWidth        ,   
        'projectMatchType'                  : this.state.projectMatchType            ,   
        'projectPatternWidth'               : this.state.projectPatternWidth         ,   
        'projectPatternLength'              : this.state.projectPatternLength        ,   
        'projectStepValue'                  : this.state.projectStepValue            ,   
        'projectDropValue'                  : this.state.projectDropValue            ,   
        'patternWidthIsVisible'             : this.state.patternWidthIsVisible       ,   
        'patternLengthIsVisible'            : this.state.patternLengthIsVisible      ,   
        'patternStepValueIsVisible'         : this.state.patternStepValueIsVisible   ,   
        'patternDropValueIsVisible'         : this.state.patternDropValueIsVisible   ,   
        'projectCutLabel'                   : this.state.projectCutLabel             ,   
        'projectCutLengthFeet'              : this.state.projectCutLengthFeet        ,   
        'projectCutLengthInches'            : this.state.projectCutLengthInches      ,   
        'projectDocument1'                  : this.state.projectDocument1            ,   
        'projectComments'                   : this.state.projectComments             ,   
        'cutFeet'                           : this.state.cutFeet                     ,         
        'cutInches'                         : this.state.cutInches                   ,  
        'cutLabel'                          : this.state.cutLabel                    ,         
        'cutLength'                         : this.state.cutLength                   ,  
        'totalCuts'                         : this.state.totalCuts                   ,  
        'orderLength'                       : this.state.orderLength                 ,   
        'minCutLength'                      : this.state.minCutLength                ,   
        'cutList'                           : this.state.cutList                     ,         
        'activeStepIsBuyerInfo'             : this.state.activeStepIsBuyerInfo       ,   
        'activeStepIsEstimationDetails'     : this.state.activeStepIsEstimationDetails     ,   
        'activeStepIsProductInfo'           : this.state.activeStepIsProductInfo     ,   
        'activeStepIsShippingInfo'          : this.state.activeStepIsShippingInfo    ,   
        'activeStepIsOrderSummary'          : this.state.activeStepIsOrderSummary    ,   
        'shippingName'                      : this.state.shippingName                ,   
        'shippingCompanyName'               : this.state.shippingCompanyName         ,   
        'shippingEmail'                     : this.state.shippingEmail               ,   
        'shippingPhone'                     : this.state.shippingPhone               ,   
        'shippingAddress'                   : this.state.shippingAddress             ,   
        'shippingAddress2'                  : this.state.shippingAddress2            ,   
        'shippingCity'                      : this.state.shippingCity                ,   
        'shippingState'                     : this.state.shippingState               ,   
        'shippingZip'                       : this.state.shippingZip                 ,   
        'shippingCountry'                   : this.state.shippingCountry             ,   
        'shippingSidemark'                  : this.state.shippingSidemark            ,   
        'shippingNote'                      : this.state.shippingNote                ,   
        'shippingAddressIsInstallationSite' : this.state.shippingAddressIsInstallationSite      ,   
        'shippingAddressIsDefault'          : this.state.shippingAddressIsDefault    ,   
        'shippingAddressIsOther'            : this.state.shippingAddressIsOther      ,   
        'shippingAddressType'               : this.state.shippingAddressType         ,   
        'productSKU'                        : this.state.productSKU                  ,

    };
    this.props.setOrderInfo(orderData);
    this.setState({
      activeStepIsBuyerInfo     : false  , 
      activeStepIsEstimationDetails   : false  , 
      activeStepIsProductInfo   : false  , 
      activeStepIsShippingInfo  : false  ,  
      activeStepIsOrderSummary  : false  ,
      activeStepIsConfirmation  : true   ,
    });
    this.doStockCheck();
  }

  doStockCheck = () => {
    var request = new XMLHttpRequest();
    //var orderNumber = toString(moment);
    //var date = new Date();
    //var stockCheckId = date.getTime();
    const stockCheckData = {
      "companyNumber": "123456789",
      "orderLength": "654",
      //"skuColor": this.state.skuColor,
      //"skuWidth": this.state.skuWidth,
      //"skuBacking": this.state.skuBacking,
      //"companyCode": this.state.companyCode,
     // "companyId": this.state.companyId,
      //"orderLength": this.state.orderLength,
      //"stockCheckId": stockCheckId,
    };
    // request.open('POST', "https://test2.floorsoft.com/wbysapi/odms/orderin");
    // request.setRequestHeader('content-type', 'application/json');
    // request.send(stockCheckData);
    axios({
      method: 'post',
      url: 'https://test2.floorsoft.com/wbysapi/odms/orderin',
      data: stockCheckData
    })
    .then(function (response) {
      console.log(response, stockCheckData);
    })
    .catch(function (error) {
      console.log(error);
    });
 
    this.setState({
      stockCheckResponse     : toString(axios.response)  ,
    });
    
  };


  



//----------------------------------------
// ---------  Reveals Optional Buyer Inputs if Buyer Name is not ""   --------
// ---------------------------------------

  showOptionalBuyerInputs = (event) => {
    switch(event.target.value) {
      case ''          : 
        this.setState({
          buyerName                : event.target.value ,
          showOptionalBuyerInputs  : false              ,
      }); break;
      default : {
        this.setState({
          buyerName                : event.target.value ,
          showOptionalBuyerInputs  : true              ,
        });
      };
  }
}

//----------------------------------------
// ---------  Filters product file and sets state of product variables   --------
// ---------------------------------------

filterProducts = () => {
var input = this.state.productSKU;

const result = PRODUCTS.find( product => product.F2SKU === input );

console.log(result);


  this.setState({
    productWidth : result.F2WTHF,
    productColor : result.F2CLR,
    productStyle : result.F2STYL,
    }); 
}

  render() {
    const { cutList, cutLabel, cutFeet, cutInches} = this.state;
    const orderLength = ( this.sumCutLengthProperty(this.state.cutList, 'cutLength') ).toFixed(4);
    const orderLengthToFeet = orderLength / 12;
    const orderFeet = parseInt(orderLengthToFeet);
    const orderInches = orderLength % 12;

    return (
      <div>
          <Container style={styles.container}>
            <Paper style={styles.paper}>

{/* **************************************************************************************** */}
{/* *******************************    BUYER INFO     ************************************** */}
{/* **************************************************************************************** */}

        {this.state.activeStepIsBuyerInfo &&
          <div >
            <Hidden smDown>
              <div style={styles.stepButtonContainer}>
                <Button  color='primary' variant='outlined' onClick={this.handleActiveStepIsBuyerInfo}><OneIcon/>Buyer Info</Button>
                <Button  onClick={this.handleActiveStepIsEstimationDetails}><TwoIcon/>Estimation Details</Button>
                <Button  onClick={this.handleActiveStepIsProductInfo}><ThreeIcon/>Product Info</Button>
                <Button  onClick={this.handleActiveStepIsShippingInfo}><FourIcon/>Shipping Info</Button>
                <Button  onClick={this.handleActiveStepIsOrderSummary}><FiveIcon/>Order Summary</Button>
              </div>
            </Hidden>

            <h3>BUYER INFO</h3>
            <Grid container spacing={3}>  
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                id="standard-buyerName"
                label="Buyer or Project Name"
                style={styles.textField}
                value={this.state.buyerName}
                onChange={this.showOptionalBuyerInputs.bind(this)}
                margin="normal"
                required
                helperText="Required"
                /> 
              </Grid>
            </Grid>

            

            {this.state.showOptionalBuyerInputs && 
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  id="standard-buyerCompanyName"
                  label="Company Name"
                  style={styles.textField}
                  value={this.state.buyerCompanyName}
                  onChange={this.handleChange.bind(this,'buyerCompanyName')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  id="standard-buyerEmail"
                  label="Email"
                  style={styles.textField}
                  value={this.state.buyerEmail}
                  onChange={this.handleChange.bind(this,'buyerEmail')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  id="standard-buyerMobilePhone"
                  label="Mobile Phone"
                  style={styles.textField}
                  value={this.state.buyerMobilePhone}
                  onChange={this.handleChange.bind(this,'buyerMobilePhone')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
              </Grid>
              
              <h3 style={{marginBottom:-10, marginTop:30}}>INSTALLATION SITE DETAILS</h3>
              <Grid container spacing={3}>  
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                  id="standard-buyerAddress"
                  label="Address"
                  style={styles.textField}
                  value={this.state.buyerAddress}
                  onChange={this.handleChange.bind(this,'buyerAddress')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                  id="standard-buyerAddress2"
                  label="Address Line 2"
                  style={styles.textField}
                  value={this.state.buyerAddress2}
                  onChange={this.handleChange.bind(this,'buyerAddress2')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
              </Grid>

              <Grid container spacing={3}> 
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerCity"
                  label="City"
                  style={styles.textField}
                  value={this.state.buyerCity}
                  onChange={this.handleChange.bind(this,'buyerCity')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerState"
                  label="State"
                  style={styles.textField}
                  value={this.state.buyerState}
                  onChange={this.handleChange.bind(this,'buyerState')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerZip"
                  label="Zip"
                  style={styles.textField}
                  value={this.state.buyerZip}
                  onChange={this.handleChange.bind(this,'buyerZip')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerCountry"
                  label="Country"
                  style={styles.textField}
                  value={this.state.buyerCountry}
                  onChange={this.handleChange.bind(this,'buyerCountry')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
              </Grid>

              <div className="Container-Flex-End" style={styles.buttonContainer}>
                <Button variant="contained" size="large" color="primary" onClick={this.handleActiveStepIsEstimationDetails}>NEXT</Button>
              </div>
            </div>
            }
           
          </div>
        }

{/* **************************************************************************************** */}
{/* *******************************    PROJECT INFO     ************************************** */}
{/* **************************************************************************************** */}        

        {this.state.activeStepIsEstimationDetails &&
        <div>
         
          <Hidden smDown>
            <div style={styles.stepButtonContainer}>
              <Button  onClick={this.handleActiveStepIsBuyerInfo}><OneIcon/>Buyer Info</Button>
              <Button  color='primary' variant='outlined' onClick={this.handleActiveStepIsEstimationDetails}><TwoIcon/>Estimation Details</Button>
              <Button  onClick={this.handleActiveStepIsProductInfo}><ThreeIcon/>Product Info</Button>
              <Button  onClick={this.handleActiveStepIsShippingInfo}><FourIcon/>Shipping Info</Button>
              <Button  onClick={this.handleActiveStepIsOrderSummary}><FiveIcon/>Order Summary</Button>
            </div>
          </Hidden>

          <h3 style={styles.title}>ESTIMATION DETAILS</h3>

          <Grid container spacing={3}>  
              <Grid item xs={6} sm={6} md={2}>
                <FormControl  style={styles.textField}>
                  <InputLabel htmlFor="standard-projectMeasuredWidth">
                    Measured Width
                  </InputLabel>
                  <Select
                    value={this.state.projectMeasuredWidth}
                    onChange={this.handleChange.bind(this,'projectMeasuredWidth')}
                    inputProps={{
                      name: 'standard-projectMeasuredWidth',
                      id: 'standard-projectMeasuredWidth',
                    }}
                  >
      
                    <MenuItem value={600}>6' 0"</MenuItem>
                    <MenuItem value={1200}>12' 0"</MenuItem>
                    <MenuItem value={1206}>12' 6"</MenuItem>
                    <MenuItem value={1300}>13' 0"</MenuItem>
                    <MenuItem value={1302}>13' 2"</MenuItem>
                    <MenuItem value={1306}>13' 6"</MenuItem>
                    <MenuItem value={1308}>13' 8"</MenuItem>
                    <MenuItem value={1500}>15' 0"</MenuItem>
                  </Select>
                  <FormHelperText>Width you measured for</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <FormControl style={styles.textField}>
                  <InputLabel htmlFor="standard-projectMatchType">
                    PR Match Type
                  </InputLabel>
                  <Select
                    value={this.state.projectMatchType}
                    onChange={this.handleMatchTypeChange.bind(this)}
                    inputProps={{
                      name: 'standard-projectMatchType',
                      id: 'standard-projectMatchType',
                    }}
                  >
                    <MenuItem value={'None'}>None</MenuItem>
                    <MenuItem value={'Set Match'}>Set Match</MenuItem>
                    <MenuItem value={'Drop Match'}>Drop Match</MenuItem>
                    <MenuItem value={'Step Match'}>Step Match</MenuItem>
                  </Select>
               
                </FormControl>
              </Grid>
              {this.state.patternWidthIsVisible &&
                <Grid item xs={6} sm={6} md={2}>
                  <FormControl style={styles.textField}>
                    <TextField
                    id="standard-projectPatternWidth"
                    label="PR Width"
                    value={this.state.projectPatternWidth}
                    onChange={this.handleChange.bind(this,'projectPatternWidth')}
                    />
                    <FormHelperText>Inches</FormHelperText>
                  </FormControl>
                </Grid>
              }
              {this.state.patternLengthIsVisible &&
                <Grid item xs={6} sm={6} md={2}>
                <FormControl style={styles.textField}>
                    <TextField
                    id="standard-projectPatternLength"
                    label="PR Length"
                    value={this.state.projectPatternLength}
                    onChange={this.handleChange.bind(this,'projectPatternLength')}
                    />
                    <FormHelperText>Inches</FormHelperText>
                  </FormControl>
                </Grid>
              }
              {this.state.patternStepValueIsVisible &&
                <Grid item xs={6} sm={6} md={2}>
                <FormControl style={styles.textField}>
                    <TextField
                    id="standard-projectStepValue"
                    label="Step Value"
                    value={this.state.projectStepValue}
                    onChange={this.handleChange.bind(this,'projectStepValue')}
                    />
                    <FormHelperText>Inches</FormHelperText>
                  </FormControl>
                </Grid>
              }
              {this.state.patternDropValueIsVisible &&
              <Grid item xs={6} sm={6} md={2}>
              <FormControl style={styles.textField}>
                  <TextField
                  id="standard-projectDropValue"
                  label="Drop Value"
                  value={this.state.projectDropValue}
                  onChange={this.handleChange.bind(this,'projectDropValue')}
                  />
                  <FormHelperText>Inches</FormHelperText>
                </FormControl>
              </Grid>
              }
            </Grid>

{/* *******************************    BEGIN CUT LIST SECTION    ************************************** */}

            <h5 style={{marginTop:25, marginBotton:15}}>ADD CUTS: </h5>

            <Grid container spacing={3} >
            
              <Grid item xs={12} md={2}>
              <FormControl style={styles.textField}>
                <TextField
                type="text"
                placeholder="Cut Label"
                name="cutLabel"
                value={cutLabel}
                onChange={this.onChangeValue}
              
                />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
              <FormControl style={styles.textField}>
                <TextField
                type="number"
                placeholder="Feet"
                name="cutFeet"
                value={cutFeet}
                onChange={this.onChangeValue}
                InputProps={{ inputProps: { min: 0, max: 999, className:'TextField' } }}
                style={styles.textField}
                />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
              <FormControl  style={{width:'100%', marginTop:-15}}>
                  <InputLabel htmlFor="standard-inches">
                    Inches
                  </InputLabel>
                  <Select
                    value={cutInches}
                    onChange={this.onChangeValueInches}
                    name="cutInches"
                    type="number"
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={11}>11</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid >

            <Grid item xs={12} md={8} style={{marginTop:35, marginBotton:35}}>
              <Card style={{backgroundColor:'transparent'}} >
                <h5 >CUT LIST </h5>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='left'>CUTS: {this.state.cutList.length}</TableCell>
                      <TableCell align='left'>ORDER LENGTH: {orderFeet}' {orderInches}" </TableCell>
                      <TableCell align='left'>MIN CUT: {this.state.minCutLength}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={styles.tableHeader}>Label</TableCell>
                      <TableCell style={styles.tableHeader}>Feet</TableCell>
                      <TableCell style={styles.tableHeader}>Inches</TableCell>
                      <TableCell style={styles.tableHeader}>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {cutList.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <TextField
                          type="text"
                          name="cutLabel"
                          value={item.cutLabel}
                          //onChange={(event) => this.onUpdateItem(event, index, 'cutLabel')}
                          />
                        </TableCell>
                        <TableCell >
                          <TextField
                          type="number"
                          name="cutFeet"
                          value={item.cutFeet}
                          //onChange={(event) => this.onUpdateItem(event, index, 'cutFeet', 'cutLength')}
                          />
                        </TableCell>
                        <TableCell >
                          <TextField
                          type="number"
                          name="cutInches"
                          value={item.cutInches}
                          //onChange={(event) => this.onUpdateItem(event, index, 'cutInches', 'cutLength')}
                          />
                        </TableCell>
                        <TableCell >
                          <Button style={styles.buttonPadding} type="button" onClick={() => this.onRemoveItem(item.cutLabel)} variant="contained">
                          Remove 
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Grid>

{/* *******************************    END CUT LIST SECTION    ************************************** */}
           
            <Grid container spacing={3} style={{marginTop:30}}>  
              <Grid item xs={12} sm={6} >
                <TextField
                  id="outlined-multiline-projectComments"
                  label="Notes / Comments"
                  multiline
                  rowsMax="4"
                  style={styles.textField}
                  value={this.state.projectComments}
                  onChange={this.handleChange.bind(this,'projectComments')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <OutlinedInput
                accept="image/*"
                style={styles.textField}
                id="contained-button-projectDocument1"
                multiple
                type="file"
                value={this.state.projectDocument1}
                onChange={this.handleChange.bind(this,'projectDocument1')}
              />
              <FormHelperText>Floor Plan, Documents, etc.</FormHelperText>
              </Grid>
            </Grid>

          <div className="Container-Flex" style={styles.buttonContainer}>
              <Button  size="large"   onClick={this.handleActiveStepIsBuyerInfo}>BACK</Button>
              <Button variant="contained" size="large" color="primary" onClick={this.handleActiveStepIsProductInfo}>NEXT</Button>
          </div>
        </div>
        }

{/* **************************************************************************************** */}
{/* *******************************    PRODUCT INFO     ************************************** */}
{/* **************************************************************************************** */}

        {this.state.activeStepIsProductInfo &&
          <div>

            <Hidden smDown>
              <div style={styles.stepButtonContainer}>
                <Button  onClick={this.handleActiveStepIsBuyerInfo}><OneIcon/>Buyer Info</Button>
                <Button  onClick={this.handleActiveStepIsEstimationDetails}><TwoIcon/>Estimation Details</Button>
                <Button  style={{color:'#3f51b5', border:'1px solid rgba(63, 81, 181, 0.5)', padding:'5px 16px', borderRadius:'4px'}} color='primary' variant='outlined' onClick={this.handleActiveStepIsProductInfo}><ThreeIcon/>Product Info</Button>
                <Button  onClick={this.handleActiveStepIsShippingInfo}><FourIcon/>Shipping Info</Button>
                <Button  onClick={this.handleActiveStepIsOrderSummary}><FiveIcon/>Order Summary</Button>
              </div>
            </Hidden>

            <div >
            <MaterialTable 
              style={{backgroundColor:'#111111'}}
              title="Search for Products:"
              columns={[
                { title: 'SKU', field: 'sku' },
                { title: 'Style', field: 'styleName', },
                { title: 'Style ID', field: 'styleId',  },
                { title: 'Color', field: 'colorName'  },
                { title: 'ColorID', field: 'colorId'  },
                { title: 'Width(ft)', field: 'widthFeet'  },
                { title: 'Width(in)', field: 'widthInches'  },
                { title: 'Backing', field: 'backingName'  },
              ]}
              data={PRODUCTS}        
              options={{
                sorting: true,
                exportButton: true,
                selection: true,
                headerStyle: {
                  backgroundColor: '#333333',
                  color: '#FFF'
                },
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  width: 400,
                  marginLeft: 25,
                }
              }}
              actions={[
                {
                  tooltip: 'Remove All Selected Users',
                  icon: 'add',
                  onClick: () => alert('Select This Product?')
                }
              ]}
              localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} of {count}',
        
                },
                toolbar: {
                    nRowsSelected: '{0} Product(s) selected',
                    searchPlaceholder : 'SKU, Style, StyleId, Color or ColorId'
                },
                header: {
                    actions: 'Actions'
                },
                body: {
                    emptyDataSourceMessage: <h4>NO PRODUCTS FOUND</h4>
                }
              }}/>
              </div>
            <h3 style={styles.title}>PRODUCT INFO</h3>

            
            <div className='Container-Flex-Center'>
              <Card style={{padding:50, backgroundColor:'#111111'}}>
                <h3>SKU: {this.state.productSKU ? this.state.productSKU : 'N/A'}</h3>
                <h3>Style: {this.state.productStyle ? this.state.productStyle : 'N/A'} ({this.state.productStyleId ? this.state.productStyleId : 'N/A'})</h3>
                <h3>Color: {this.state.productColor ? this.state.productColor : 'N/A'} ({this.state.productColorId ? this.state.productColorId : 'N/A'})</h3>
                <h3>Backing: {this.state.productStyle ? this.state.productStyle : 'N/A'}</h3>
                <h3>Width: {this.state.productWidthFeet ? this.state.productWidthFeet : 'N/A'}' {this.state.productWidthInches ? this.state.productWidthInches : 'N/A'}"</h3>
              </Card>
            </div>

            <Grid container spacing={3} style={{marginTop:20}}>
             <Grid item xs={12} sm={12} md={12}>
              <h5>PRODUCT SPECS</h5>
                <Card style={{backgroundColor:'#111'}}>
                  <BootStrapTable borderless size="sm" style={styles.orderSummaryTable}>
                    <tbody>
                    <tr>
                      <td style={styles.tableCellBold}>SKU:</td>
                      <td>{this.state.productSKU ? this.state.productSKU : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Width:</td>
                      <td>{this.state.productMFGWidth ? this.state.productMFGWidth : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Next Spec:</td>
                      <td>{this.state.productMFGWidth ? this.state.productMFGWidth : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>Style:</td>
                      <td>{this.state.productStyle ? this.state.productStyle : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Match Type:</td>
                      <td>{this.state.productMatchType ? this.state.productMatchType : 'N/A'}</td>
                      <td style={styles.tableCellBold}>FaceWeight:</td>
                      <td>{this.state.productFaceWeight ? this.state.productFaceWeight : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>Color:</td>
                      <td>{this.state.productColor ? this.state.productColor : 'N/A'}</td>
                      <td style={styles.tableCellBold}>PR Width:</td>
                      <td>{this.state.productPRWidth ? this.state.productPRWidth : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Twist:</td>
                      <td>{this.state.productTwist ? this.state.productTwist : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>SubType:</td>
                      <td>{this.state.productSubtype ? this.state.productSubtype : 'N/A'}</td>
                      <td style={styles.tableCellBold}>PR Length:</td>
                      <td>{this.state.productPRLength ? this.state.productPRLength : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Next Spec:</td>
                      <td>{this.state.productMFGWidth ? this.state.productMFGWidth : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>Backing: </td>
                      <td>{this.state.productBacking ? this.state.productBacking : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Step Value:</td>
                      <td>{this.state.productStepValue ? this.state.productStepValue : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Next Spec:</td>
                      <td>{this.state.productMFGWidth ? this.state.productMFGWidth : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>Next Spec:</td>
                      <td>{this.state.productMFGWidth ? this.state.productMFGWidth : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Drop Value</td>
                      <td>{this.state.productDropValue ? this.state.productDropValue : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Next Spec:</td>
                      <td>{this.state.productMFGWidth ? this.state.productMFGWidth : 'N/A'}</td>
                    </tr>
                    </tbody>
                  </BootStrapTable>
                </Card>
             </Grid>
            </Grid>

            <Grid container spacing={3} style={{marginTop:30}}>  
              <Grid item xs={12} sm={4} >
                <FormControlLabel
                control={
                <Checkbox checked={this.state.productIsFaceIn} onClick={this.handleProductIsFaceIn} value={this.state.productIsFaceIn} />
                }
                label="Face In"
                color="primary"
                />
              </Grid>
              <Grid item xs={12} sm={4} >
                <FormControlLabel
                control={
                <Checkbox checked={this.state.productIsWrapped} onClick={this.handleProductIsWrapped} value={this.state.productIsWrapped} />
                }
                label="Wrapped Rolls"
                color="primary"
                />
              </Grid>
              <Grid item xs={12} sm={4} >
                <FormControlLabel
                control={
                <Checkbox checked={this.state.productSelvidgeOn} onClick={this.handleProductSelvidgeOn} value={this.state.productSelvidgeOn} />
                }
                label="Selvidge Off"
                color="primary"
                />
              </Grid>
            </Grid>
            
            <div className="Container-Flex" style={styles.buttonContainer}>
                <Button size="large" onClick={this.handleActiveStepIsEstimationDetails}>BACK</Button>
                <Button variant="contained" size="large" color="primary" onClick={this.handleActiveStepIsShippingInfo}>NEXT</Button>
            </div>
          </div>
        }

{/* **************************************************************************************** */}
{/* *******************************    SHIPPING INFO     ************************************** */}
{/* **************************************************************************************** */}

        {this.state.activeStepIsShippingInfo &&
          <div>

            <Hidden smDown>
              <div style={styles.stepButtonContainer}>
                <Button  onClick={this.handleActiveStepIsBuyerInfo}><OneIcon/>Buyer Info</Button>
                <Button  onClick={this.handleActiveStepIsEstimationDetails}><TwoIcon/>Estimation Details</Button>
                <Button  onClick={this.handleActiveStepIsProductInfo}><ThreeIcon/>Product Info</Button>
                <Button  color='primary' variant='outlined' onClick={this.handleActiveStepIsShippingInfo}><FourIcon/>Shipping Info</Button>
                <Button  onClick={this.handleActiveStepIsOrderSummary}><FiveIcon/>Order Summary</Button>
              </div>
            </Hidden>

            <h3 style={styles.title}>SHIPPING INFO</h3>

            <Grid container spacing={3} style={{marginTop:30}}>  
              <Grid item xs={12} sm={4} >
                <FormControlLabel
                control={
                <Checkbox checked={this.state.shippingAddressIsDefault} onChange={this.handleShippingAddressIsDefault} value={this.state.shippingAddressIsDefault} />
                }
                label="Default Shipping Address"
                color="primary"
                />
              </Grid>
              <Grid item xs={12} sm={4} >
                <FormControlLabel
                control={
                <Checkbox checked={this.state.shippingAddressIsInstallationSite} onChange={this.handleshippingAddressIsInstallationSite} value={this.state.shippingAddressIsInstallationSite} />
                }
                label="Installation Site"
                color="primary"
                />
              </Grid>
              <Grid item xs={12} sm={4} >
                <FormControlLabel
                control={
                <Checkbox checked={this.state.shippingAddressIsOther} onChange={this.handleShippingAddressIsOther} value={this.state.shippingAddressIsOther} />
                }
                label="Add Another Address"
                color="primary"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>  
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                id="standard-shippingName"
                label="Shipping Contact"
                style={styles.textField}
                value={this.state.shippingName}
                onChange={this.handleChange.bind(this,'shippingName')}
                margin="normal"
                /> 
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                id="standard-shippingCompanyName"
                label="Shipping Company Name"
                style={styles.textField}
                value={this.state.shippingCompanyName}
                onChange={this.handleChange.bind(this,'shippingCompanyName')}
                margin="normal"
                /> 
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                id="standard-shippingEmail"
                label="Email"
                style={styles.textField}
                value={this.state.shippingEmail}
                onChange={this.handleChange.bind(this,'shippingEmail')}
                margin="normal"
                /> 
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                id="standard-shippingPhone"
                label="Phone"
                style={styles.textField}
                value={this.state.shippingPhone}
                onChange={this.handleChange.bind(this,'shippingPhone')}
                margin="normal"
                /> 
              </Grid>
            </Grid>
      
            <Grid container spacing={3}>  
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                id="standard-shippingAddress"
                label="Street Address"
                style={styles.textField}
                value={this.state.shippingAddress}
                onChange={this.handleChange.bind(this,'shippingAddress')}
                margin="normal"
                /> 
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                id="standard-shippingAddress2"
                label="Address Line 2"
                style={styles.textField}
                value={this.state.shippingAddress2}
                onChange={this.handleChange.bind(this,'shippingAddress2')}
                margin="normal"
                /> 
              </Grid>
            </Grid>

            <Grid container spacing={3}> 
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="standard-shippingCity"
                label="City"
                style={styles.textField}
                value={this.state.shippingCity}
                onChange={this.handleChange.bind(this,'shippingCity')}
                margin="normal"
                /> 
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="standard-shippingState"
                label="State"
                style={styles.textField}
                value={this.state.shippingState}
                onChange={this.handleChange.bind(this,'shippingState')}
                margin="normal"
                /> 
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="standard-shippingZip"
                label="Zip"
                style={styles.textField}
                value={this.state.shippingZip}
                onChange={this.handleChange.bind(this,'shippingZip')}
                margin="normal"
                /> 
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                id="standard-shippingCountry"
                label="Country"
                style={styles.textField}
                value={this.state.shippingCountry}
                onChange={this.handleChange.bind(this,'shippingCountry')}
                margin="normal"
                /> 
              </Grid>
            </Grid>

            


              <div className="Container-Flex" style={styles.buttonContainer}>
                <Button  size="large"  onClick={this.handleActiveStepIsProductInfo}>BACK</Button>
                <Button variant="contained" size="large" color="primary"  onClick={this.handleActiveStepIsOrderSummary}>NEXT</Button>
              </div>
          </div>
        }

{/* **************************************************************************************** */}
{/* *******************************    ORDER SUMMARY     ************************************** */}
{/* **************************************************************************************** */}

        {this.state.activeStepIsOrderSummary &&
          <div>
            <Hidden smDown>
              <div style={styles.stepButtonContainer}>
                <Button  onClick={this.handleActiveStepIsBuyerInfo}><OneIcon/>Buyer Info</Button>
                <Button  onClick={this.handleActiveStepIsEstimationDetails}><TwoIcon/>Estimation Details</Button>
                <Button  onClick={this.handleActiveStepIsProductInfo}><ThreeIcon/>Product Info</Button>
                <Button  onClick={this.handleActiveStepIsShippingInfo}><FourIcon/>Shipping Info</Button>
                <Button  color='primary' variant='outlined' onClick={this.handleActiveStepIsOrderSummary}><FiveIcon/>Order Summary</Button>
              </div>
            </Hidden>

            <Grid container spacing={3}>
             <Grid item xs={12} sm={12} md={6}>
             <h5>BUYER INFO</h5>
              <Card style={{backgroundColor:'#111'}}>
               
                <Table size="small" style={{borderBottomColor:'transparent'}}>
                  <TableBody>
                  <TableRow style={{borderBottomColor:'transparent'}}>
                    <TableCell>Name:</TableCell>
                    <TableCell>{this.state.buyerName ? this.state.buyerName : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Company:</TableCell>
                    <TableCell>{this.state.buyerCompanyName ? this.state.buyerCompanyName : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone:</TableCell>
                    <TableCell>{this.state.buyerMobilePhone ? this.state.buyerMobilePhone : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>{this.state.buyerEmail ? this.state.buyerEmail : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address:</TableCell>
                    <TableCell>{this.state.buyerAddress ? this.state.buyerAddress : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address Line 2:</TableCell>
                    <TableCell>{this.state.buyerAddress2 ? this.state.buyerAddress2 : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      {this.state.buyerCity ? this.state.buyerbuyerCity : 'N/A'}, {this.state.buyerState ? this.state.buyerState : 'N/A'} &nbsp; {this.state.buyerZip ? this.state.buyerZip : 'N/A'} &nbsp; {this.state.buyerCountry ? this.state.buyerCountry : 'N/A'}
                    </TableCell>
                  </TableRow>
                  </TableBody>
                </Table>
              </Card>
             </Grid>

             <Grid item xs={12} sm={12} md={6}>
             <h5>SHIPPING INFO</h5>
              <Card style={{backgroundColor:'#111'}}>
             
                <Table size="small" style={styles.orderSummaryTable}>
                  <TableBody>
                  <TableRow>
                    <TableCell>Name:</TableCell>
                    <TableCell>{this.state.shippingName ? this.state.shippingName : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Company:</TableCell>
                    <TableCell>{this.state.shippingCompanyName ? this.state.shippingCompanyName : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone:</TableCell>
                    <TableCell>{this.state.shippingPhone ? this.state.shippingPhone : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email:</TableCell>
                    <TableCell>{this.state.shippingEmail ? this.state.shippingEmail : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address:</TableCell>
                    <TableCell>{this.state.shippingAddress ? this.state.shippingAddress : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address 2:</TableCell>
                    <TableCell>{this.state.shippingAddress2 ? this.state.shippingAddress2 : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      {this.state.shippingCity ? this.state.shippingCity : 'N/A'}, {this.state.shippingState ? this.state.shippingState : 'N/A'} &nbsp; {this.state.shippingZip ? this.state.shippingZip : 'N/A'} &nbsp; {this.state.shippingCountry ? this.state.shippingCountry : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>SideMark:</TableCell>
                    <TableCell>{this.state.shippingSidemark ? this.state.shippingSidemark : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Note:</TableCell>
                    <TableCell>{this.state.shippingNote ? this.state.shippingNote : 'N/A'}</TableCell>
                  </TableRow>
                  </TableBody>
                </Table>
              </Card>
             </Grid>
            </Grid>
            
            <Grid container spacing={3}>
            
            <Grid item xs={12} sm={12} md={5}>
            <h5>ESTIMATION DETAILS</h5>
                <Card style={{backgroundColor:'#111'}}>
                  <BootStrapTable borderless size="sm" style={styles.orderSummaryTable}>
                    <tbody>
                    <tr>
                      <td>Measured Width:</td>
                      <td>{this.state.projectMeasuredWidth ? this.state.projectMeasuredWidth : 'N/A'}</td>
                      <td>Match Type:</td>
                      <td>{this.state.projectMatchType ? this.state.projectMatchType : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td >PR Width:</td>
                      <td>{this.state.projectPatternWidth ? this.state.projectPatternWidth : 'N/A'}</td>
                      <td>PR Length:</td>
                      <td>{this.state.projectPatternLength ? this.state.projectPatternLength : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td>Step Value:</td>
                      <td>{this.state.projectStepValue ? this.state.projectStepValue : 'N/A'}</td>
                      <td>Drop Value:</td>
                      <td>{this.state.projectDropValue ? this.state.projectDropValue : 'N/A'}</td>
                    </tr>
                    </tbody>
                  </BootStrapTable>
                </Card>
            </Grid>

             <Grid item xs={12} sm={12} md={7}>
             <h5>CUT LIST</h5>
              <Card style={{backgroundColor:'#111'}}>
              <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='left'>CUTS: {this.state.cutList.length}</TableCell>
                      <TableCell align='left'>ORDER LENGTH: {orderFeet}' {orderInches}" </TableCell>
                      <TableCell align='left'>MIN CUT: {this.state.minCutLength}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Table size="small" style={{borderBottomColor:'transparent'}}>
                <TableHead>
                    <TableRow>
                      <TableCell >Label</TableCell>
                      <TableCell >Feet</TableCell>
                      <TableCell >Inches</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {cutList.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {item.cutLabel}
                        </TableCell>
                        <TableCell >
                          {item.cutFeet}
                        </TableCell>
                        <TableCell >
                          {item.cutInches}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
             </Grid>            
            </Grid>

            <Grid container spacing={3}>
             <Grid item xs={12} sm={12} md={12}>
              <h5>PRODUCT INFO</h5>
                <Card style={{backgroundColor:'#111'}}>
                  <BootStrapTable borderless size="sm" style={styles.orderSummaryTable}>
                    <tbody>
                    <tr>
                      <td style={styles.tableCellBold}>SKU:</td>
                      <td>{this.state.productSKU ? this.state.productSKU : 'N/A'}</td>
                      <td style={styles.tableCellBold}>Width:</td>
                      <td>MFG Width</td>
                      <td style={styles.tableCellBold}>Width:</td>
                      <td>MFG Width</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>Style:</td>
                      <td>Style Name</td>
                      <td style={styles.tableCellBold}>Match Type:</td>
                      <td>PR Match Type</td>
                      <td style={styles.tableCellBold}>Match Type:</td>
                      <td>PR Match Type</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>Color:</td>
                      <td>Color Name</td>
                      <td style={styles.tableCellBold}>PR Width:</td>
                      <td>PR Width</td>
                      <td style={styles.tableCellBold}>Match Type:</td>
                      <td>PR Match Type</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>SubType:</td>
                      <td>SubType Name</td>
                      <td style={styles.tableCellBold}>PR Length:</td>
                      <td>PR Length:</td>
                      <td style={styles.tableCellBold}>Match Type:</td>
                      <td>PR Match Type</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>Backing: </td>
                      <td>Backing Type</td>
                      <td style={styles.tableCellBold}>Step Value:</td>
                      <td>Step Value</td>
                      <td style={styles.tableCellBold}>Match Type:</td>
                      <td>PR Match Type</td>
                    </tr>
                    <tr>
                      <td style={styles.tableCellBold}>SubType:</td>
                      <td>SubType Name</td>
                      <td style={styles.tableCellBold}>Drop Value</td>
                      <td>Drop Value</td>
                      <td style={styles.tableCellBold}>Match Type:</td>
                      <td>PR Match Type</td>
                    </tr>
                    </tbody>
                  </BootStrapTable>
                </Card>
             </Grid>
            </Grid>

              <div className="Container-Flex" style={styles.buttonContainer}>
                <Button  size="large"   onClick={this.handleActiveStepIsShippingInfo}>BACK</Button>
                <Button variant="contained" size="large" color="primary" onClick={this.handleFormComplete}>Submit</Button>
              </div>
          </div>
        }
        {this.state.activeStepIsConfirmation &&
          <div>
           
            <h5>ORDER CONFIRMATION</h5>

            <Card style={{backgroundColor:'transparent'}}>
              {WaitScreen.showWaitScreen()}
              {this.state.stockCheckResponse}

            </Card>

            <div className="Container-Flex" style={styles.buttonContainer}>
              <LogoffButton accnt onClick={this.handleResetForm} />
              <Button variant="contained" size="large" color="primary" onClick={this.handleResetForm}>CREATE NEXT ORDER</Button>
              <Link to="/dashboard" variant="contained" size="large" color="primary" onClick={this.handleResetForm}>
                FINISH
              </Link>
            </div>

          </div>
        }
            
            </Paper>
        </Container>
      </div>
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "orderData" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  //let orderData = state.orderInfo.filter(curState => curState.orderData); // This will return only object called "orderData" and return an array of size 1 fi it exists
  //if (orderData.length > 0) orderData = orderData[0].orderData;     // "orderData[0]" is grabing the element found on the filter. ".orderData" is the actual object name inside of the main datastructure
  return { orderInfo : state.orderInfo, // All Data available for orderInfo reducer/datastructure.
         };
};

// ---------------------------------------------------------------------------
// --- This Allows Us To Update The Store From Within The Screen/Component ---
// ---------------------------------------------------------------------------
const mapDispatchToProps = dispatch => ({
 setOrderInfo : (orderData) => { return dispatch(orderActions.setOrderInfo(orderData)); }
});


export default connect(mapStateToProps,mapDispatchToProps)(PlaceOrder);