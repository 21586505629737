export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-dashboard',
    
    },
    {
      name: 'Place Order',
      url: '/placeOrder',
      icon: 'fa fa-shopping-cart',
      
    },
    {
      name: 'Order History',
      url: '/orderHistory',
      icon: 'fa fa-history',
      
    },
    /* {
      name: 'Supplier Info',
      url: '/supplierInfo',
      icon: 'fa fa-table',
  
    },
    {
      name: 'Order Strategy',
      url: '/orderStrategy',
      icon: 'fa fa-bullseye',
    
    },
    {
      name: 'Order Statuses',
      url: '/orderStatuses',
      icon: 'fa fa-calendar',

    }, */
    {
      name: 'Company Info',
      url: '/companyInfo',
      icon: 'fa fa-building-o',

    },
    {
      name: 'User Info',
      url: '/userInfo',
      icon: 'fa fa-user-circle-o',
    
    },
   /*  {
      name: 'B to B',
      url: '/btoB',
      icon: 'fa fa-handshake-o',
    
    },
    {
      name: 'Import',
      url: '/import',
      icon: 'fa fa-download',

    },
    {
      name: 'My Styles',
      url: '/myStyles',
      icon: 'fa fa-cogs',
    
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: 'fa fa-exclamation-triangle',
      
    },
    {
      name: 'Export',
      url: '/export',
      icon: 'fa fa-external-link',
    
    },
    {
      name: 'Install Docs',
      url: '/installDocs',
      icon: 'fa fa-print',
    
    }, */
    {
      name: 'Order Parameters',
      url: '/orderParameters',
      icon: 'fa fa-server',
      
    },
  ]
};


