import React, { Component } from 'react';

class OrderStatuses extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        <h2>Order Statuses</h2>
      </div>
    );
  }
}

OrderStatuses.propTypes = {

};

export default OrderStatuses;