import companyApi from "../api/companyApi";
import types from "./actionTypes";

// --------------------------------------------------------------
// --- Function(s) to be dispatched to Reducer for processing ---
// --------------------------------------------------------------
export function dataSuccess(companyInfo) {
  return { type: types.ORDER_LOAD_SUCCESS, companyInfo };
}

// ------------------
// ---  Order ---
// ------------------
export function setCompanyInfo(companyData) {
  let companyInfo = [companyData]; // Creates a variable holding your most recent changes from the screen
 /*  if (prevState) { // Protect against the prevState being null because it will be when you first load the application
    // We then loop through all the available states & add all the found states EXCEPT companyData
    // because we want to replace the old "companyData" object with the new one coming in from the screen
    for (let i = 0; i < prevState.length; i++) {
      const curState = prevState[i];
      if (curState && !curState.companyData) {
        companyInfo.push(curState);
      }
    }
  } */
  // Dispatches "dataSuccess" function passing in the constructed array to update the companyInfo Reducer
  return (dispatch) => {
    dispatch(dataSuccess(companyInfo));
  }
}