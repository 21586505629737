import keyMirror from "keymirror";

export default keyMirror({
    BEGIN_AJAX_CALL: null,
    CREATE_REFIGURE_FAILURE: null,
    CREATE_REFIGURE_SUCCESS: null,
    LOAD_PROJECTS_FAILURE: null,
    LOAD_PROJECTS_SUCCESS: null,
    LOAD_SALES_DOCUMENTS_FAILURE: null,
    LOAD_SALES_DOCUMENTS_SUCCESS: null,
    LOAD_FLOOR_PLANS_FAILURE: null,
    LOAD_FLOOR_PLANS_SUCCESS: null,
    LOGIN_FAILURE: null,
    LOGIN_SUCCESS: null,
    LOGIN_REQUEST: null,
    LOGOUT_FAILURE: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_REQUEST: null,
    REGISTER_FAILURE: null,
    REGISTER_SUCCESS: null,
    REGISTER_REQUEST: null,
    UPDATE_PROJECT_FAILURE: null,
    UPDATE_PROJECT_SUCCESS: null,


    PROJECT_SEARCH_SUCCESS:null,
    PROJECT_SEARCH_FAILURE:null,
    REFIGURE_LOAD_SUCCESS: null,
    REFIGURE_LOAD_FAILURE:null,
    REFIGURE_UPDATE_SUCCESS:null,
    REFIGURE_UPDATE_FAILURE:null,

    ORDER_LOAD_SUCCESS:null,

    COMPANY_LOAD_SUCCESS:null,


});
