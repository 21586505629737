import React, { Component } from 'react';

function formatName(orderData) {
  return orderData.firstName + ' ' + orderData.lastName;
}

const orderData = {
  firstName: 'Lewis',
  lastName: 'Hardeman'
};

const element = (
  <h1>
    Hello, {formatName(orderData)}!
  </h1>
);

class OrderHistory extends Component {
  render() {
    return (
      <div>
        {element}
      </div>
    );
  }
}

OrderHistory.propTypes = {

};

export default OrderHistory;
