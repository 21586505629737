import React, { Component } from 'react';

class OrderStrategy extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        <h2>Order Strategy</h2>
      </div>
    );
  }
}

OrderStrategy.propTypes = {

};

export default OrderStrategy;
