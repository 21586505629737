import { combineReducers } from 'redux';
import auth from './loginReducer';
import orderInfo from './orderReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';

const rootReducer = combineReducers({
  auth                : auth,
  orderInfo           : orderInfo,
  ajaxCallsInProgress : ajaxCallsInProgress,
});

export default rootReducer;
