import React, { Component } from 'react';

class Export extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        <h2>Export</h2>
      </div>
    );
  }
}

Export.propTypes = {

};

export default Export;
