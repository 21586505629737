import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import {
  loginUser,
  logoutUser,
} from '../../../actions/userActions';
//import { styles } from 'material-ui-pickers/DatePicker/components/Calendar';
import LockOpenIcon from '@material-ui/icons/LockOpen';


class LogoffButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      user: {
        username: '',
        password: '',
      },
      showLoginModal: false,
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleLoginModal = () => {
    this.setState({
      showLoginModal: !this.state.showLoginModal,
    });
  }

  onLoginClick = (e) => {
    e.preventDefault();
    this.props.dispatch(loginUser(this.state.user))
      .then(() => {
        this.toggleLoginModal();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onLogoutClick = () => {
    this.props.dispatch(logoutUser())
      .then(() => {
        if (this.props.errorMessage) {
          // TODO: Show error message notification
          console.log(this.props.errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateUserState = (e) => {
    const { name, value } = e.target;
    this.setState({
      user: {
        ...this.state.user,
        [name]: value
      }
    });
  }



  dropAccnt = () => {
    const styles = {
      icon: {
        marginRight: 10,
      },
      iconLeft: {
        marginLeft: 10,
      },
      iconBigger: {
        fontSize: 30,
      },
      buttonSpace: {
        marginRight: 20,
        marginLeft: 10,
      },
    };

    return (
     
            <div>
                  <Button variant="contained" size="large" color="primary"  onClick={this.onLogoutClick}>
                    Logoff
                    <LockOpenIcon style={styles.iconLeft}/>
                  </Button>
            </div>
    );
  }


  render() {
    const { notif, accnt, tasks, mssgs } = this.props;
    return (
      notif ? this.dropNotif() :
      accnt ? this.dropAccnt() :
      tasks ? this.dropTasks() :
      mssgs ? this.dropMssgs() : null
    );
  }
}

LogoffButton.propTypes = {
  notif: PropTypes.bool,
  accnt: PropTypes.bool,
  tasks: PropTypes.bool,
  mssgs: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  errorMessage: PropTypes.string,
};

LogoffButton.defaultProps = {
  notif: false,
  accnt: false,
  tasks: false,
  mssgs: false,
  isAuthenticated: false,
};

function mapStateToProps(state) {
  const { auth } = state;
  const { isAuthenticated, errorMessage } = auth;
  return {
    isAuthenticated,
    errorMessage
  };
}


export default connect(mapStateToProps)(LogoffButton);
