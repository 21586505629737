
export default {
  orderInfo: [],
  companyInfo: [],
  numAjaxCallsInProgress: 0,
  auth: {
    isFetching: false,
    isAuthenticated: localStorage.getItem('access_token') ? true : false
  },
};
