import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



class RememberUser extends React.Component {
  state = {
    remember: false,
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {



    const { remember } = this.state;
  

    const styles={
      gray:{
        color: "#444444",
      }
    };



    return (
      <div >
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel 
              control={
                <Checkbox checked={remember} onChange={this.handleChange('remember')} value="remember" style={styles.gray} />
              }
              label='Remember me on this device'
            />
          </FormGroup>
        </FormControl>
      </div>
    );
  }
}

RememberUser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default RememberUser;