import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Form,
  FormGroup,
//  Input,
  InputGroup,
} from 'reactstrap';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import RememberUser from './RememberUser';





const styles = {
  buttonMargin: {
    marginTop: 30,
  },
  checkboxMargin: {
    marginTop: -30,
  }
};



const LoginForm = ({ user, handleChange, handleSubmit}) => {

  return (
    <Form>
      <FormGroup>
      <InputGroup className='mb-3'>
          <FormControl className="LoginField">
              <InputLabel htmlFor="username">
                Username
              </InputLabel>
              <Input
                type='text'
                name='username'
                id='username'
                variant="outlined"
                value={user.username}
                onChange={handleChange}
                style={styles.textField}
              />
          </FormControl>
        </InputGroup>
      </FormGroup>
      <FormGroup>
      <InputGroup className='mb-4'>
          <FormControl className="LoginField">
              <InputLabel htmlFor="password">
                Password
              </InputLabel>
              <Input
                type='text'
                name='password'
                id='password'
                variant="outlined"
                value={user.password}
                onChange={handleChange}
                style={styles.textField}
              />
          </FormControl>
        </InputGroup>
        <InputGroup>
        
        
        </InputGroup>
      </FormGroup>
      <Row style={styles.checkboxMargin}>
        <Col >
          <RememberUser />
        </Col>
      </Row>
      <Row style= {styles.buttonMargin}>
        <Col xs='6' >
          <Button color='link' className='px-0' onClick={""}>Forgot password?</Button>
        </Col>
{/*         <Col xs='3' >
          <Button color='link' className='px-0' onClick={'/userInfo'}>Create Account</Button>
        </Col> */}
        <Col xs='6' className='text-right'>
          <Button variant="contained" color='primary' onClick={handleSubmit}>Login</Button>
        </Col>
      </Row>
    </Form>
  );
};

LoginForm.propTypes = {
  user: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default LoginForm;

