import React from 'react';
//import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Card, CardImg, } from 'reactstrap';
import photo from './wbysLogo.png';


const styles = {
  divMain: {
    position:'relative',
    width:'50%',
    //height:'20%',
    top:'50%',
    //bottom:'35%',
    left:0,
    right:0,
    textAlign:'center',
    backgroundColor: '#000',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 150,
    marginBottom: 150,
  },
};

export function showWaitScreen() {
  return (
    <div style={styles.divMain}>
      <div>
        <Card style={{backgroundColor:'#000', borderColor:'#000'}}>
          <CardImg style={{}} src = { photo } alt="Card image cap" />
        </Card>
        <LinearProgress color="primary"/>
      </div>
    </div>
  )
}
