import 'react-app-polyfill/ie11';
import 'flag-icon-css/css/flag-icon.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './styles/scss/style.scss';
import './styles/scss/_custom.scss';
import './styles/scss/core/_dropdown-menu-right.scss';
import React                                      from 'react';
import ReactDOM                                   from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import WBYS                                from './containers/WBYS';
import configureStore                             from './store/configureStore';
//import * as projectActions                        from './actions/projectActions';
import { Provider     }                           from 'react-redux';
import * as serviceWorker                         from './serviceWorker';
//import moment from 'moment-timezone';

// --------------------
// --- Create Store ---
// --------------------
//const dateTo   = moment().tz(moment.tz.guess()).format('YYYY-MM-DD');
//let   dateFrom = moment().tz(moment.tz.guess()).subtract(30,'days').format('YYYY-MM-DD');
//const dateFrom = '2018-12-01'; // Hardcodded for ow because there are NO new projects on DB. Will be replaced by line above...
//const dateTo   = '2018-01-01'; // Hardcodded for ow because there are NO new projects on DB. Will be replaced by line above...
const store = configureStore();
//store.dispatch(projectActions.projectSearch(dateFrom,dateTo));
console.log('index - accessToken = ',localStorage.getItem('access_token'));

// ------------------------------------
// --- Main Creation Of Application ---
// ------------------------------------
ReactDOM.render((
  <Provider store = { store } >
    <Router>
      <Switch>
        <Route path='/' name='WBYS' component = { WBYS } />
      </Switch>
    </Router>
  </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
