import React, { Component } from 'react';

class MyStyles extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        <h2>My Styles</h2>
      </div>
    );
  }
}

MyStyles.propTypes = {

};

export default MyStyles;
