import React, { Component } from 'react';
import * as Utility from './../Utility/Utility.js';

class Notifications extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        <Utility.UtilityCard txtHeader = { 'this is a header'} txtBody = { 'This is the body'} />
      </div>
    );
  }
}

Notifications.propTypes = {

};

export default Notifications;
