import React, { Component } from 'react';

class Import extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        <h2>Import</h2>
      </div>
    );
  }
}

Import.propTypes = {

};

export default Import;
