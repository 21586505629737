import React, { Component } from 'react';
import { Container, Button, Link } from 'react-floating-action-button';
import '../../styles/scss/FAB.scss';


class FAB extends Component {
    render() {
      return (
        <div className='animated fadeIn'>
          <Container>
                <Link href="https://www.floorsoft.com/Page_Support.html"
                    tooltip="Notifications"
                    icon="fa fa-exclamation-triangle" />
                <Link href="/userInfo"
                    tooltip="Edit User Info"
                    icon="fa fa-address-card" />
                 <Link href="/orderHistory"
                    tooltip="Order History"
                    icon="fa fa-history" />
                <Link href="/placeOrder"
                    tooltip="Place a New Order"
                    icon="fa fa-cart-plus" />
               
               <Button
               
               tooltip="Screen Help"
               icon="fa fa-question" 
               rotate={false}
               />
             
          
           
             
                       
               
                 
               
            </Container>
        </div>
      );
    }
  }
  
 FAB.propTypes = {
  
  };
  
  export default FAB;

