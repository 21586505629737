import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
//import HeaderDropdown from './HeaderDropdown';
//import Button from '@material-ui/core/Button';
import Logoff from './Logoff';



class Header extends Component {
  sidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  sidebarMinimize(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-minimized');
  }

  mobileSidebarToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle(e) {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  render() {

    var sPath = window.location.pathname;
    var sPage = sPath.substring(sPath.lastIndexOf('/')+1);

    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
        <NavbarBrand  href="#"></NavbarBrand>
        <NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
          <span className="navbar-toggler-icon"></span>
        </NavbarToggler>
            <div className='PageName HideElement'>
             
            </div>
            
        <Nav className="ml-auto" navbar>

            {/* Logoff & Support buttons hidden @ < 1000px */}
            <ul className="navbar-nav HideElement mr-3 ml-0">
              {/* <NavLink href="https://www.floorsoft.com/Page_Support.html"><i className="fa fa-question-circle fa-2x" /></NavLink> */}
              <Logoff accnt />
            </ul>

            {/* Logoff button is icon only @ > 1000px */}
            <ul className="navbar-nav ShowElement ">
            <Logoff accnt />
            </ul>
            
            {/* <HeaderDropdown accnt /> */}
        </Nav>
       
      </header>
    );
  }
}

export default Header;
