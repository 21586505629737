import initialState from './initialState';
import types from '../actions/actionTypes';

export default function orderReducer(state = initialState.orderInfo, action) {
 switch (action.type) {
   case types.ORDER_LOAD_SUCCESS:
     const newState = []; // Create A new Array Represeting The New State And Concatenate The Current State
     //if (newState.length > 0) newState.splice(0,1); // If The New State Is Not Empty, Empty It.
     console.log('reducer = ', action);
     return [...newState, ...action.orderInfo ]; // Returns The New State, Adding The Action Coming From Screen. Adding The Selected Refigures In This Case..
   default:
     return state;
     
 }
 
}
