import types from './actionTypes';
import UserApi from '../api/userApi';

function requestLogin(creds) {
  return {
    type: types.LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds: creds
  };
}

function recieveLogin() {
  return {
    type: types.LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
  };
}

function loginError(message) {
  return {
    type: types.LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message: message
  };
}

function requestLogout() {
  return {
    type: types.LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  };
}

function receiveLogout() {
  return {
    type: types.LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
}

/*function logoutError(message) {
  return {
    type: types.LOGOUT_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message: message
  };
}*/

/*function requestRegister(user) {
  return {
    type: types.REGISTER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
  };
}*/

/*function recieveRegister() {
  return {
    type: types.REGISTER_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}*/

/*function registerError(message) {
  return {
    type: types.REGISTER_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message: message,
  };
}*/

export function loginUser(user) {
  return (dispatch) => {
    dispatch(requestLogin(user));
    return UserApi.login(user, false)
      .then((response) => {
        const msg = response.lstMessages;
        if (msg.length > 0) {
          if (msg[0] === 'Session Logon Status Logged On') {
            localStorage.setItem('access_token', response.sessionToken);
            dispatch(recieveLogin());
          }
          else if (msg[0] === 'Session Logon Status Request Override') {
            dispatch(loginUserOverride(user));
          }
          else {
            dispatch(loginError(msg[0]));
          }
        }
      })
      .catch((err) => {
        throw(err);
      });
  }
}

export function loginUserOverride(user) {
  return (dispatch) => {
    dispatch(requestLogin(user));
    return UserApi.login(user, true)
      .then((response) => {
        const msg = response.lstMessages;
        if (msg.length > 0) {
          if (msg[0] === 'Session Logon Status Logged On') {
            localStorage.setItem('access_token', response.sessionToken);
            dispatch(recieveLogin());
          }
          else {
            dispatch(loginError(msg[0]));
          }
        }
      })
      .catch((err) => {
        throw(err);
      });
  }
}

export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    return UserApi.logout()
      .then((response) => {
        localStorage.removeItem('access_token');
        dispatch(receiveLogout());
      })
      .catch((err) => {
        throw(err);
      });
  }
}
