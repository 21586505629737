import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Container} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as orderActions from '../../actions/orderActions';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LogoffButton from '../common/Header/LogoffButton.js'
import {Table as BootStrapTable}  from 'reactstrap';
import Input from '@material-ui/core/Input';
import * as companyActions from '../../actions/companyActions';
import companyDefaultInfo from '../../api/companyDefaultInfo.json';



const styles = {
  textField: {
    width: '100%',
  },
  selectInput: {
    width: '100%',
    marginTop: 2,
  },
  container: {
    backgroundColor: '#000',
    padding:"2.5rem",
    
  },
  paper : {
    backgroundColor: 'transparent',

  },
  buttonContainer : {
    backgroundColor: 'none',
    marginTop: 35,
  },
  title : {
    marginTop: '2.5rem',
  },
  stepButtonContainer :{
    display:'flex', 
    justifyContent:'space-between',
    marginBottom: 30
  },
  stepButtonIcon : {
    marginRight: 10,
  },
  addButtonPadding :{
    paddingRight:20,
    paddingLeft:20,
  },
  tableHeader : {
    fontSize: '1.1rem',
  },
  orderSummaryTable :{
    backgroundColor: '#111',
    color: '#fff'
  },
  tablePaddingLeft50 : {
    paddingLeft : 50,
    paddingTop : 5,
    paddingBottom : 5,
  },
  tablePaddingLeft25 : {
    paddingLeft : 25,
    paddingTop : 5,
    paddingBottom : 5,
  },
  tableCellBold : {
    fontWeight: 600,
  },

}

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
        retailerDefaultContact          :  '',
        companyID                       : '12345',
        
        
    }; 
    this.handleChange = this.handleChange.bind(this);                                           
  }  

  handleChange(changeType, event) {
    switch(changeType) {
      case 'retailerDefaultContact'                     : this.setState({retailerDefaultContact                    : event.target.value}); break;

      default:;
    }
  }

  // ----------------------------
  // --- Update Buttton Listener ---
  // ----------------------------
  handleUpdateCompany = () => {
    let companyData = {
        'retailerDefaultContact'                     : this.state.retailerDefaultContact                   ,  
        
    };
    this.props.setCompanyInfo(companyData);    
  }


  filterCompanyDefaultInfo = () => {
    
    const result = companyDefaultInfo.find( company => company.companyID === this.state.companyID );
    
    console.log(result);
    // expected output: 12
    
      this.setState({
        retailerDefaultContact : result.retailerDefaultContact,
        }); 
    }
  
  render() {
    return (
      <div>

{/* **************************************************************************************** */}
{/* *******************************    BUYER INFO     ************************************** */}
{/* **************************************************************************************** */}
          <Card>
            <h3>COMPANY INFO</h3>

            <Button onClick={this.filterCompanyDefaultInfo}>Console Log</Button>
            <Grid container spacing={3}>  
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                id="standard-buyerName"
                label="Buyer or Project Name"
                style={styles.textField}
                value={this.state.buyerName}
                onChange={this.handleChange.bind(this, 'buyerName')}
                margin="normal"
                required
                helperText="Required"
                /> 
              </Grid>
            </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  id="standard-buyerCompanyName"
                  label="Company Name"
                  style={styles.textField}
                  value={this.state.buyerCompanyName}
                  onChange={this.handleChange.bind(this,'buyerCompanyName')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  id="standard-buyerEmail"
                  label="Email"
                  style={styles.textField}
                  value={this.state.buyerEmail}
                  onChange={this.handleChange.bind(this,'buyerEmail')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                  id="standard-buyerMobilePhone"
                  label="Mobile Phone"
                  style={styles.textField}
                  value={this.state.buyerMobilePhone}
                  onChange={this.handleChange.bind(this,'buyerMobilePhone')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
              </Grid>
              
              <Grid container spacing={3}>  
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                  id="standard-buyerAddress"
                  label="Address"
                  style={styles.textField}
                  value={this.state.buyerAddress}
                  onChange={this.handleChange.bind(this,'buyerAddress')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                  id="standard-buyerAddress2"
                  label="Address Line 2"
                  style={styles.textField}
                  value={this.state.buyerAddress2}
                  onChange={this.handleChange.bind(this,'buyerAddress2')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
              </Grid>

              <Grid container spacing={3}> 
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerCity"
                  label="City"
                  style={styles.textField}
                  value={this.state.buyerCity}
                  onChange={this.handleChange.bind(this,'buyerCity')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerState"
                  label="State"
                  style={styles.textField}
                  value={this.state.buyerState}
                  onChange={this.handleChange.bind(this,'buyerState')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerZip"
                  label="Zip"
                  style={styles.textField}
                  value={this.state.buyerZip}
                  onChange={this.handleChange.bind(this,'buyerZip')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                  id="standard-buyerCountry"
                  label="Country"
                  style={styles.textField}
                  value={this.state.buyerCountry}
                  onChange={this.handleChange.bind(this,'buyerCountry')}
                  margin="normal"
                  helperText="Optional"
                  /> 
                </Grid>
              </Grid>

              <div className="Container-Flex-End" style={styles.buttonContainer}>
                <Button variant="contained" size="large" color="primary" onClick={this.handleActiveStepIsProjectInfo}>UPDATE</Button>
              </div>
            </Card>

            <Grid container spacing={3}>  
              <Grid item xs={12} sm={6} md={6}>
                <Card>
                  <BootStrapTable>
                    <tbody>
                      <tr>
                        <td>
                          <h5>Primary Contact:</h5>
                        </td>
                        <td>
                          <h5>{this.state.retailerDefaultContact}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </BootStrapTable>
                  
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Card>saved 2

                </Card>
              </Grid>

            </Grid>


      </div>
      
    );
  }
}

// ------------------------------------------------------------------------------------
// --- This Method Allows The Component To Have Access To The Store Data "companyData" ---
// ------------------------------------------------------------------------------------
function mapStateToProps (state) {
  //let companyData = state.companyInfo.filter(curState => curState.companyData); // This will return only object called "companyData" and return an array of size 1 fi it exists
  //if (companyData.length > 0) companyData = companyData[0].companyData;     // "companyData[0]" is grabing the element found on the filter. ".companyData" is the actual object name inside of the main datastructure
  return { companyInfo : state.companyInfo, // All Data available for companyInfo reducer/datastructure.
         };
};

// ---------------------------------------------------------------------------
// --- This Allows Us To Update The Store From Within The Screen/Component ---
// ---------------------------------------------------------------------------
const mapDispatchToProps = dispatch => ({
 setCompanyInfo : (companyData) => { return dispatch(companyActions.setCompanyInfo(companyData)); }
});


export default connect(mapStateToProps,mapDispatchToProps)(CompanyInfo);
