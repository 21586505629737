import React     from 'react';
import PropTypes from 'prop-types';
import Home      from './Home';
import LoginPage from './common/LoginPage';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
}); 

/* const theme = createMuiTheme({
   palette: {
     primary: {
       main: '#4cab50',
     },
     secondary: {
       main: '#ff9100',
     },
   }
 });  */

const App = ({ onLoginUser, isAuthenticated, errorMessage, ...props }) => {
  return (
     <MuiThemeProvider theme={theme}>
      <div>
        { isAuthenticated ? ( <Home /> ) : ( <LoginPage onLoginUser = { onLoginUser } errorMessage = { errorMessage } /> ) }
      </div>
     </MuiThemeProvider>
  );
};
/*const App = ({ onLoginUser, isAuthenticated, errorMessage, ...props }) => {
  return (
    <div>
      { ( <Home /> ) }
    </div>
  );
};*/


App.propTypes = {
  onLoginUser     : PropTypes.func.isRequired,
  isAuthenticated : PropTypes.bool.isRequired,
  errorMessage    : PropTypes.string,
};

export default App;
