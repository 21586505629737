import React, { Component } from 'react';

class InstallDocs extends Component {
  render() {
    return (
      <div className='animated fadeIn'>
        <h2>Install Docs</h2>
      </div>
    );
  }
}

InstallDocs.propTypes = {

};

export default InstallDocs;
