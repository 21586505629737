import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    minWidth: 275,
    marginTop: 200,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};



export const UtilityCard = ({txtHeader,txtBody}) => {
  return (
    <div>
      {txtHeader && txtBody &&
        <Card style={styles.card}>
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            {txtHeader}
          </Typography>
          <Typography variant="h5" component="h2">
            {txtBody}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">View Report</Button>
        </CardActions>
      </Card>
      }
    </div>
  )
};
