import React from 'react';
import { Button, Card, CardBody, Badge } from 'reactstrap';
import {Link} from 'react-router-dom';
import MaterialTable from 'material-table';



const styles = {
  bodyTxt: {
    fontSize: "1.5rem",
    fontWeight: 300,
    textAlign: "center",
  },
  txtGreen: {
    color: "#0fe200",
    fontSize: "2.5rem",
    fontWeight: 700,
    textAlign: "center",
  },
  txtRed: {
    color: "#d30000",
    fontSize: "2.5rem",
    fontWeight: 700,
    textAlign: "center",
  },
  txtBlue: {
    color: "#0077ff",
    fontSize: "2.5rem",
    fontWeight: 700,
    textAlign: "center",
  }
}


export const DashboardCardOrderHistory = () => {
  return (
    <div>
      {
       <Card inverse style={{ backgroundColor: '#111', borderColor: '#424242' }}>
       
        <MaterialTable style={{ backgroundColor: '#111', borderColor: '#424242' }}
        
        title="Order History"
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Order Number', field: 'orderNumber', type: 'numeric' },
          { title: 'Order Length', field: 'orderLength',  },
          { title: 'Savings', field: 'savings'  },
          { title: 'Status', field: 'status'  },
        
        ]}
        data={[
          { name: 'Jason Smith', orderNumber: 100436789, orderLength: '66/0' ,  savings: '$52.23',  status: 'Submitted'  },
          { name: 'El Patron', orderNumber: 100245687, orderLength: '24/0' ,  savings: '$22.87',  status: 'Complete'  },
          { name: 'Hulk Hogan', orderNumber: 100892435, orderLength: '37/0' ,  savings: '$42.13',  status: 'Shipped'  },
          { name: 'Frank Thomas', orderNumber: 100858957, orderLength: '33/0' ,  savings: '$102.34', status: 'Back Order'  },
          { name: 'Josh Donaldson', orderNumber: 100775956, orderLength: '101/0',  savings: '$12.02',  status: 'Shipped'  },
        ]}        
        options={{
          sorting: true,
          exportButton: true,
          // searchFieldStyle: {color:"#fff"}
        }}

      />
      
          <div className="Container-Flex-End">
              <Link to="#">
                <Button color="link">
                  VIEW REPORT
                </Button>
              </Link>   
          </div>
       </Card>    
      }
    </div>
  )
};

export const DashboardCardBackOrders = () => {
  return (
    <div>
      {
       <Card inverse style={{ backgroundColor: '#111', borderColor: '#424242' }}>
        <MaterialTable
        style={{ backgroundColor: '#111', borderColor: '#424242' }} 
        title="BackOrders"
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Order Number', field: 'orderNumber', type: 'numeric' },
          { title: 'Order Length', field: 'orderLength',  },
          { title: 'Savings', field: 'savings'  },
          { title: 'Status', field: 'status'  },
        
        ]}
        data={[
          { name: 'Jason Smith', orderNumber: 100436789, orderLength: '66/0' ,  savings: '$52.23',  status: 'Submitted'  },
          { name: 'El Patron', orderNumber: 100245687, orderLength: '24/0' ,  savings: '$22.87',  status: 'Complete'  },
          { name: 'Hulk Hogan', orderNumber: 100892435, orderLength: '37/0' ,  savings: '$42.13',  status: 'Shipped'  },
        
        ]}        
        options={{
          sorting: true,
          
        }}

        
      />            
          <div className="Container-Flex-End">
              <Link to="#">
                <Button color="link">
                  VIEW REPORT
                </Button>
              </Link>   
          </div>
       </Card>    
      }
    </div>
  )
};


export const DashboardCardSavings = ({txtHeader, txtBody, txtPill}) => {

  return (
    <div>
      {txtHeader && txtBody && txtPill &&
       <Card inverse style={{ backgroundColor: '#111', borderColor: '#424242' }}>
        <div className="Card-Header-Flex">
            <h4>{txtHeader}</h4>
            <h1><Badge color="success" pill>{txtPill}</Badge></h1>
        </div> 

        <CardBody style={styles.bodyTxt}>
            <span style={{color:"#0fe200"}}>Total Savings</span><br/>
            <span style={styles.txtGreen} >{txtBody}</span>
        </CardBody>

  
          <div className="Container-Flex-End">
              <Link to="#">
                <Button color="link">
                  VIEW REPORT
                </Button>
              </Link>   
          </div>
       </Card>    
      }
    </div>
  )
};


export const DashboardCardTopSupplier = ({txtHeader,txtBody}) => {
  return (
    <div>
      {
       <Card inverse style={{ backgroundColor: '#111', borderColor: '#424242' }}>
        <div className="Card-Header-Flex-Start">
            <h4>{txtHeader}</h4>
        </div> 
        <CardBody style={styles.bodyTxt}>
            <span >{txtBody}</span>
        </CardBody>
          <div className="Container-Flex-End">
              <Link to="#">
                <Button color="link">
                  VISIT SITE
                </Button>
              </Link>   
          </div>
       </Card>    
      }
    </div>
  )
};

export const ProductSelectionTable = () => {
  return (
    <div>
        <MaterialTable 
        
        style={{ backgroundColor: '#111', borderColor: '#424242' }}
        options={{
          sorting: true,
          exportButton: true,
          selection: true,
          headerStyle: {
            backgroundColor: '#111111',
            color: '#FFF'
          },
        }}
        title="Select a Product"
        columns={[
          { title: 'SKU', field: 'sku' },
          { title: 'Style', field: 'styleName' },
          { title: 'Style ID', field: 'styleId'  },
          { title: 'Color', field: 'colorName'  },
          { title: 'ColorID', field: 'colorId'  },
          { title: 'Width(ft)', field: 'widthFeet'  },
          { title: 'Width(in)', field: 'widthInches'  },
          { title: 'Backing', field: 'backingName'  },
        ]}
        data={[
          { "sku":"111111", "styleName":"Costa Rica","styleId":"123", "colorName":"Brown", "colorId":"111", "widthFeet":"12", "widthInches":"0", "backingName":"ActionBac"},
          { "sku":"222222", "styleName":"Costa Rica","styleId":"123", "colorName":"Tan",   "colorId":"222", "widthFeet":"13", "widthInches":"2", "backingName":"ActionBac"},
          { "sku":"333333", "styleName":"Costa Rica","styleId":"123", "colorName":"White", "colorId":"333", "widthFeet":"15", "widthInches":"0", "backingName":"KangaBac"},
          { "sku":"444444", "styleName":"Belize",    "styleId":"456", "colorName":"Brown", "colorId":"111", "widthFeet":"12", "widthInches":"0", "backingName":"ActionBac"},
          { "sku":"555555", "styleName":"Belize",    "styleId":"456", "colorName":"Tan",   "colorId":"222", "widthFeet":"13", "widthInches":"2", "backingName":"ActionBac"},
          { "sku":"666666", "styleName":"El Patron", "styleId":"789", "colorName":"White", "colorId":"333", "widthFeet":"12", "widthInches":"0", "backingName":"ActionBac"},
          { "sku":"777777", "styleName":"El Patron", "styleId":"789", "colorName":"Brown", "colorId":"111", "widthFeet":"12", "widthInches":"0", "backingName":"KangaBac"},
          { "sku":"888888", "styleName":"El Patron", "styleId":"789", "colorName":"Tan",   "colorId":"222", "widthFeet":"15", "widthInches":"0", "backingName":"ActionBac"},
          { "sku":"999999", "styleName":"Owls beak", "styleId":"111", "colorName":"White", "colorId":"333", "widthFeet":"12", "widthInches":"0", "backingName":"KangaBac"},
          { "sku":"123456", "styleName":"Excitable", "styleId":"222", "colorName":"Brown", "colorId":"111", "widthFeet":"15", "widthInches":"0", "backingName":"KangaBac"},
        ]}        
        options={{
          sorting: true,
          exportButton: true,
          selection: true,
          // searchFieldStyle: {color:"#fff"}
        }}
        //onSelectionChange={(rows) => alert(rows.length + ' Product Selected')}
         actions={[
          {
            tooltip: 'Remove All Selected Users',
            icon: 'delete',
            onClick: (evt, data) => alert('Select This Product?')
          }
        ]} 
      /> 
    </div>
  )
};

