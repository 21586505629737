import React, { Component } from 'react';
//import PropTypes from 'prop-types';


class SupplierInfo extends Component {
  //constructor(props, context) {
  //  super(props, context);
  //}

  render() {
    return (
      <div className='animated fadeIn'>
        <h2>Supplier Info</h2>
      </div>
    );
  }
}

SupplierInfo.propTypes = {

};

export default SupplierInfo;
