import OrderApi from "../api/orderApi";
import types from "./actionTypes";

// --------------------------------------------------------------
// --- Function(s) to be dispatched to Reducer for processing ---
// --------------------------------------------------------------
export function dataSuccess(orderInfo) {
  return { type: types.ORDER_LOAD_SUCCESS, orderInfo };
}

// ------------------
// ---  Order ---
// ------------------
export function setOrderInfo(orderData) {
  let orderInfo = [orderData]; // Creates a variable holding your most recent changes from the screen
 /*  if (prevState) { // Protect against the prevState being null because it will be when you first load the application
    // We then loop through all the available states & add all the found states EXCEPT orderData
    // because we want to replace the old "orderData" object with the new one coming in from the screen
    for (let i = 0; i < prevState.length; i++) {
      const curState = prevState[i];
      if (curState && !curState.orderData) {
        orderInfo.push(curState);
      }
    }
  } */
  // Dispatches "dataSuccess" function passing in the constructed array to update the orderInfo Reducer
  return (dispatch) => {
    dispatch(dataSuccess(orderInfo));
  }
}
